import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import AllApis from "app/Apis";
import { Axios } from "index";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import "./popup.css";

const disabledStyling = {
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#475259",
  },
  "& .MuiInputLabel-root": {
    color: "#475259", // Change label color to blue
  },
};

export const FetchRawQtyModal = ({
  open,
  setOpen,
  rowData,
  indexNo,
  dispatchedDetails,
  setDispatchedDetails,
}) => {
  const [RawAvlData, setRawAvlData] = useState([]);
  const [page, setPage] = useState(1);
  const [runApi, setRunApi] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [balanceQty, setBalanceQty] = useState();
  const [palleteList, setPalleteList] = useState([]);
  const [palletNo, setPalletNo] = useState("");
  const [dispatchedQty, setDispatchedQty] = useState({
    item_no: 0,
    dispatch: [],
    total_pattas: 0,
    // total_pattas: {
    //   natural: 0,
    //   dyed: 0,
    //   smoked: 0,
    //   total: 0,
    // },
    item_sqm: 0,
    total_amount: 0,
    pallete_no: "",
  });
  console.log(dispatchedQty, "dispatchedQty");

  const handleClose = () => {
    setOpen(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setRunApi(!runApi);
  };

  const handleAllCheckbox = (e) => {
    const { checked } = e.target;
    let previousData = [...dispatchedQty["dispatch"]];
    console.log(RawAvlData, "RawAvlData");
    if (checked) {
      const AddAll = RawAvlData.filter(
        (ele) =>
          !previousData.some((prev) => prev?.raw_material_id === ele?._id)
      ).map((row) => {
        const sqm = parseFloat(
          (row?.item_length * row?.item_width * row?.item_available_pattas) /
            10000
        ).toFixed(2);
        const total_item_amount = parseFloat(sqm * rowData?.order_rate).toFixed(
          2
        );
        console.log(sqm, "total_item_amount");
        console.log(total_item_amount, "total_item_amount");
        return {
          raw_material_id: row?._id,
          // natural:
          //   row?.item_available_quantities?.natural > 0
          //     ? Number(row?.item_available_quantities?.natural)
          //     : 0,
          // dyed:
          //   row?.item_available_quantities?.dyed > 0
          //     ? Number(row?.item_available_quantities?.dyed)
          //     : 0,
          // smoked:
          //   row?.item_available_quantities?.smoked > 0
          //     ? Number(row?.item_available_quantities?.smoked)
          //     : 0,
          // total: row?.item_available_quantities?.total,
          dispatched_quantity:
            row?.item_available_pattas > 0
              ? Number(row?.item_available_pattas)
              : 0,
          total_item_amount: Number(total_item_amount),
          dispatch_sqm: Number(sqm),
        };
      });
      previousData.push(...AddAll);
      console.log(previousData, "previousData");
    } else {
      const unCheckData = previousData.filter(
        (ele) => !RawAvlData.some((data) => data._id === ele?.raw_material_id)
      );
      // .map((row) => {
      //   console.log(row,"********")
      //   const sqm = parseFloat(
      //     (row?.item_length *
      //       row?.item_width *
      //       row?.item_available_quantities?.total) /
      //       10000
      //   ).toFixed(2);
      //   const total_item_amount = parseFloat(
      //     sqm * rowData?.order_rate
      //   ).toFixed(2);

      //   return {
      //     raw_material_id: row?._id,
      //     natural:
      //       row?.item_available_quantities?.natural > 0
      //         ? Number(row?.item_available_quantities?.natural)
      //         : 0,
      //     dyed:
      //       row?.item_available_quantities?.dyed > 0
      //         ? Number(row?.item_available_quantities?.dyed)
      //         : 0,
      //     smoked:
      //       row?.item_available_quantities?.smoked > 0
      //         ? Number(row?.item_available_quantities?.smoked)
      //         : 0,
      //     total: row?.item_available_quantities?.total,
      //     total_item_amount: Number(total_item_amount),
      //     dispatch_sqm: Number(sqm),
      //   };
      // });
      previousData = [...unCheckData];
    }
    console.log(previousData, "previousData");
    const finalData = previousData.reduce(
      (acc, ele) => {
        if (ele) {
          // acc.total_pattas.natural += ele?.natural || 0;
          // acc.total_pattas.dyed += ele?.dyed || 0;
          // acc.total_pattas.smoked += ele?.smoked || 0;
          // acc.total_pattas.total += ele?.total || 0;
          acc.total_pattas += ele?.dispatched_quantity || 0;
          acc.item_sqm += parseFloat(ele?.dispatch_sqm?.toFixed(2)) || 0;
          acc.total_amount += ele?.total_item_amount || 0;
        }
        return acc;
      },
      {
        total_pattas: 0,
        // total_pattas: {
        //   natural: 0,
        //   dyed: 0,
        //   smoked: 0,
        //   total: 0,
        // },
        item_sqm: 0,
        total_amount: 0,
      }
    );
    setBalanceQty(finalData?.total_pattas);
    setDispatchedQty((prev) => ({
      ...prev,
      ...finalData,
      dispatch: previousData,
      item_no: rowData?.item_no,
    }));
  };

  const handleSingleCheckbox = (e, row) => {
    const { checked } = e.target;
    let previousData = [...dispatchedQty["dispatch"]];
    if (checked) {
      const sqm = parseFloat(
        (row?.item_length * row?.item_width * row?.item_available_pattas) /
          10000
      ).toFixed(2);
      const total_item_amount = parseFloat(sqm * rowData?.order_rate).toFixed(
        2
      );
      const addSingle = {
        raw_material_id: row?._id,

        // natural:
        //   row?.item_available_quantities?.natural > 0
        //     ? Number(row?.item_available_quantities?.natural)
        //     : 0,
        // dyed:
        //   row?.item_available_quantities?.dyed > 0
        //     ? Number(row?.item_available_quantities?.dyed)
        //     : 0,
        // smoked:
        //   row?.item_available_quantities?.smoked > 0
        //     ? Number(row?.item_available_quantities?.smoked)
        //     : 0,
        // total: row?.item_available_quantities?.total,
        dispatched_quantity:
          row?.item_available_pattas > 0
            ? Number(row?.item_available_pattas)
            : 0,
        total_item_amount: Number(total_item_amount),
        dispatch_sqm: Number(sqm),
      };
      previousData.push(addSingle);
    } else {
      const unCheckData = previousData.filter(
        (ele) => ele.raw_material_id !== row?._id
      );
      previousData = [...unCheckData];
    }
    const finalData = previousData.reduce(
      (acc, ele) => {
        if (ele) {
          // acc.total_pattas.natural += ele.natural || 0;
          // acc.total_pattas.dyed += ele.dyed || 0;
          // acc.total_pattas.smoked += ele.smoked || 0;
          // acc.total_pattas.total += ele.total || 0;
          acc.total_pattas += ele.dispatched_quantity || 0;
          acc.item_sqm += parseFloat(ele.dispatch_sqm?.toFixed(2)) || 0;
          acc.total_amount += ele.total_item_amount || 0;
        }
        return acc;
      },
      {
        total_pattas: 0,
        // total_pattas: {
        //   natural: 0,
        //   dyed: 0,
        //   smoked: 0,
        //   total: 0,
        // },
        item_sqm: 0,
        total_amount: 0,
      }
    );
    setBalanceQty(finalData?.total_pattas);
    setDispatchedQty((prev) => ({
      ...prev,
      ...finalData,
      dispatch: previousData,
      item_no: rowData?.item_no,
    }));
  };
  const handleOnChange = (e, data) => {
    const { name, value } = e.target;
    console.log(name, value);
    let previousData = [...dispatchedQty["dispatch"]];
    const index = previousData.findIndex(
      (obj) => obj.raw_material_id === data?._id
    );
    if (value != "" && value != 0) {
      // To add
      // const sqm = parseFloat(
      //   (data?.item_length *
      //     data?.item_width *
      //     ((name === "natural" ? Number(value) : 0) +
      //       (name === "dyed" ? Number(value) : 0) +
      //       (name === "smoked" ? Number(value) : 0))) /
      //     10000
      // ).toFixed(2);
      const sqm = parseFloat(
        (data?.item_length * data?.item_width * Number(value)) / 10000
      ).toFixed(2);

      const total_item_amount = parseFloat(sqm * rowData?.order_rate).toFixed(
        2
      );
      const qtyData = {
        raw_material_id: data?._id,
        dispatched_quantity: Number(value),
        // natural: name == "natural" ? Number(value) : 0,
        // dyed: name == "dyed" ? Number(value) : 0,
        // smoked: name == "smoked" ? Number(value) : 0,
        // total:
        //   (name === "natural" ? Number(value) : 0) +
        //   (name === "dyed" ? Number(value) : 0) +
        //   (name === "smoked" ? Number(value) : 0),
        total_item_amount: Number(total_item_amount),
        dispatch_sqm: Number(sqm),
      };

      if (
        previousData.filter((ele) => ele?.raw_material_id == data?._id).length >
        0
      ) {
        previousData[index] = qtyData;
      } else {
        // previousData[index] = qtyData;
        previousData.push(qtyData);
      }
    } else {
      // To remove
      previousData.splice(index, 1);
    }

    const finalData = previousData.reduce(
      (acc, ele) => {
        if (ele) {
          // acc.total_pattas.natural += ele.natural || 0;
          // acc.total_pattas.dyed += ele.dyed || 0;
          // acc.total_pattas.smoked += ele.smoked || 0;
          // acc.total_pattas.total += ele.total || 0;

          acc.total_pattas += ele.dispatched_quantity || 0;
          acc.item_sqm += parseFloat(ele.dispatch_sqm?.toFixed(2)) || 0;
          acc.total_amount += ele.total_item_amount || 0;
        }
        return acc;
      },
      {
        total_pattas: 0,
        // total_pattas: {
        //   natural: 0,
        //   dyed: 0,
        //   smoked: 0,
        //   total: 0,
        // },
        item_sqm: 0,
        total_amount: 0,
      }
    );
    setDispatchedQty((prev) => ({
      ...prev,
      ...finalData,
      dispatch: previousData,
      item_no: rowData?.item_no,
    }));
    setBalanceQty(finalData?.total_pattas);
  };
  useEffect(() => {
    (async () => {
      const palleteList = await Axios.get(
        `${AllApis.dropdownList.palleteNoBasedOnItemNameAndCode(
          rowData?.order_item_name,
          rowData?.order_item_code
        )}`
      );
      setPalleteList(palleteList?.data?.result);
    })();
    const isDataFilledBefore = dispatchedDetails?.filter(
      (ele) => ele?.item_no == rowData?.item_no
    );
    if (isDataFilledBefore.length > 0) {
      setDispatchedQty(isDataFilledBefore[0]);
      setBalanceQty(dispatchedDetails?.[0]?.total_pattas);
      setPalletNo(dispatchedDetails?.[0]?.pallete_no);
    } else {
      setBalanceQty(0);
    }
  }, []);
  useEffect(() => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const fetchData = async () => {
      try {
        const AvlGroupData = await Axios.post(
          `${AllApis.dispatch.getAvlRawData}?page=${page}&item_pallete_no=${palletNo}`,
          {
            item_name: rowData?.order_item_name,
            item_code: rowData?.order_item_code,
          },
          config
        );
        setRawAvlData(AvlGroupData?.data?.result);
        setTotalPage(AvlGroupData?.data?.totalPages);
      } catch (error) {
        setRawAvlData([]);
      }
    };
    open && fetchData();
  }, [open, runApi, palletNo]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={1000}>
      <DialogContent>
        <Typography variant="h3" color="initial" fontWeight={600}>
          Add Dispatched Quantity (Item No : {rowData?.item_no})
        </Typography>
        <Div
          sx={{
            display: "flex",
            columnGap: 5,
            mt: 2,
            mb: 2,
            alignItems: "center",
          }}
        >
          <Typography variant="h5" color="initial" fontWeight={600}>
            Required Quantity : {rowData?.balance_quantity}
          </Typography>
          <Typography variant="h5" color="initial" fontWeight={600}>
            Dispatching Quantity : {balanceQty}
          </Typography>
          <Autocomplete
            freeSolo
            sx={{ width: "20%", textTransform: "capitalize", ml: "auto" }}
            size="small"
            id="company-autocomplete"
            options={palleteList || []}
            value={palletNo || ""}
            isOptionEqualToValue={(option, value) => {
              return option == value?.pallete_no;
            }}
            getOptionLabel={(option) => {
              if (option instanceof Object) {
                return option.pallete_no;
              } else {
                return option;
              }
            }}
            onChange={(e, newValue) => {
              setBalanceQty(0);
              // setDispatchedQty({
              //   item_no: 0,
              //   dispatch: [],
              //   total_pattas: {
              //     natural: 0,
              //     dyed: 0,
              //     smoked: 0,
              //     total: 0,
              //   },
              //   item_sqm: 0,
              //   total_amount: 0,
              // });
              setPalletNo(newValue != null ? newValue?.pallete_no : "");
              setDispatchedQty((prev) => ({
                ...prev,
                pallete_no: newValue != null ? newValue?.pallete_no : "",
              }));
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option?.pallete_no}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Pallet No" />
            )}
          />
        </Div>
        <TableContainer component={Paper} sx={{ minHeight: "60vh" }}>
          <Table border={2}>
            <TableHead>
              <TableRow
                sx={{
                  bgcolor: "#7352C7",
                  color: "white",
                  "& .MuiTableCell-root": {
                    py: 2,
                  },
                }}
              >
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "60px",
                    verticalAlign: "middle",
                    px: 2,
                    color: "white",
                  }}
                >
                  <Checkbox
                    size="small"
                    color="info"
                    onClick={(event) => handleAllCheckbox(event)}
                    checked={
                      RawAvlData?.length > 0 &&
                      RawAvlData.every((ele) =>
                        dispatchedQty["dispatch"]?.some(
                          (item) => item.raw_material_id === ele._id
                        )
                      )
                    }
                    sx={{
                      "&.Mui-checked": {
                        color: "white",
                      },
                    }}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Bundle No.
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Log No.
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Pallet No.
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Length (cm)
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Width (cm)
                </TableCell>
                {/* {rowData?.order_item_code?.toLowerCase() == "natural" && (
                  <TableCell
                    sx={{
                      textAlign: "left",
                      width: "100px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Available Naturals
                  </TableCell>
                )}
                {rowData?.order_item_code?.toLowerCase() == "dyed" && (
                  <TableCell
                    sx={{
                      textAlign: "left",
                      width: "120px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Available Dyed
                  </TableCell>
                )}
                {rowData?.order_item_code?.toLowerCase() == "smoked" && (
                  <TableCell
                    sx={{
                      textAlign: "left",
                      width: "100px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Available Smoked
                  </TableCell>
                )} */}
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Available Pattas
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "120px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Available Sq.m.
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Dispatch Pattas
                </TableCell>
                {/* {rowData?.order_item_code?.toLowerCase() == "natural" && (
                  <TableCell
                    sx={{
                      textAlign: "left",
                      width: "100px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Dispatch Naturals
                  </TableCell>
                )}
                {rowData?.order_item_code?.toLowerCase() == "dyed" && (
                  <TableCell
                    sx={{
                      textAlign: "left",
                      width: "120px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Dispatch Dyed
                  </TableCell>
                )}
                {rowData?.order_item_code?.toLowerCase() == "smoked" && (
                  <TableCell
                    sx={{
                      textAlign: "left",
                      width: "100px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Dispatch Smoked
                  </TableCell>
                )} */}
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "120px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Dispatch Sq.m.
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Rate Per Sq.m.
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "120px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Dispatch Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {RawAvlData?.map((row, i) => (
                <TableRow key={i}>
                  <TableCell sx={{ textAlign: "left", px: 2 }}>
                    <Checkbox
                      size="small"
                      color="primary"
                      // disabled
                      sx={{
                        "&.Mui-disabled": {
                          // Override styles when checkbox is disabled
                          color: (theme) => theme.palette.primary.main, // Set color to red
                        },
                      }}
                      onClick={(e) => handleSingleCheckbox(e, row)}
                      checked={
                        dispatchedQty["dispatch"].filter(
                          (ele) => ele?.raw_material_id == row?._id
                        )?.length > 0
                      }
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.item_bundle_no}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.item_log_no}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.item_pallete_no}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.item_length}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.item_width}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.item_available_pattas}
                  </TableCell>
                  {/* {rowData?.order_item_code?.toLowerCase() == "natural" && (
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.item_available_quantities?.natural}
                    </TableCell>
                  )}
                  {rowData?.order_item_code?.toLowerCase() == "dyed" && (
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.item_available_quantities?.dyed}
                    </TableCell>
                  )}
                  {rowData?.order_item_code?.toLowerCase() == "smoked" && (
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.item_available_quantities?.smoked}
                    </TableCell>
                  )} */}
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.item_available_sqm}
                  </TableCell>

                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    <TextField
                      disabled={row?.item_available_pattas == 0}
                      size="small"
                      name="dispatched_quantity"
                      type="number"
                      sx={{ width: "100%" }}
                      inputProps={{
                        min: 0,
                        max: row?.item_available_pattas,
                      }}
                      onKeyDown={(e) => {
                        console.log(
                          "vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv",
                          dispatchedQty["dispatch"].filter(
                            (ele) => ele?.raw_material_id == row?._id
                          )?.[0] || ""
                        );
                        const inputValue = parseInt(e.target.value + e.key, 10);

                        if (
                          isNaN(inputValue) ||
                          inputValue < 0 ||
                          inputValue > row?.item_available_pattas
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => handleOnChange(e, row)}
                      value={
                        dispatchedQty["dispatch"].filter(
                          (ele) => ele?.raw_material_id == row?._id
                        )?.[0]?.dispatched_quantity || ""
                      }
                    />
                  </TableCell>

                  {/* {rowData?.order_item_code?.toLowerCase() == "dyed" && (
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      <TextField
                        disabled={row?.item_available_quantities?.dyed == 0}
                        size="small"
                        name="dyed"
                        type="number"
                        sx={{ width: "100%" }}
                        inputProps={{
                          min: 0,
                          max: row?.item_available_quantities?.dyed,
                        }}
                        onKeyDown={(e) => {
                          const inputValue = parseInt(
                            e.target.value + e.key,
                            10
                          );

                          if (
                            isNaN(inputValue) ||
                            inputValue < 0 ||
                            inputValue > row?.item_available_quantities?.dyed
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => handleOnChange(e, row)}
                        value={
                          dispatchedQty["dispatch"].filter(
                            (ele) => ele?.raw_material_id == row?._id
                          )?.[0]?.dyed || ""
                        }
                      />
                    </TableCell>
                  )}
                  {rowData?.order_item_code?.toLowerCase() == "smoked" && (
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      <TextField
                        disabled={row?.item_available_quantities?.smoked == 0}
                        size="small"
                        name="smoked"
                        type="number"
                        sx={{ width: "100%" }}
                        inputProps={{
                          min: 0,
                          max: row?.item_available_quantities?.smoked,
                        }}
                        onKeyDown={(e) => {
                          const inputValue = parseInt(
                            e.target.value + e.key,
                            10
                          );

                          if (
                            isNaN(inputValue) ||
                            inputValue < 0 ||
                            inputValue > row?.item_available_quantities?.smoked
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => handleOnChange(e, row)}
                        value={
                          dispatchedQty["dispatch"].filter(
                            (ele) => ele?.raw_material_id == row?._id
                          )?.[0]?.smoked || ""
                        }
                      />
                    </TableCell>
                  )} */}

                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {dispatchedQty["dispatch"].filter(
                      (ele) => ele?.raw_material_id == row?._id
                    )?.[0]?.dispatch_sqm
                      ? dispatchedQty["dispatch"].filter(
                          (ele) => ele?.raw_material_id == row?._id
                        )?.[0]?.dispatch_sqm
                      : "--"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {rowData?.order_rate}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {dispatchedQty["dispatch"].filter(
                      (ele) => ele?.raw_material_id == row?._id
                    )?.[0]?.total_item_amount
                      ? dispatchedQty["dispatch"].filter(
                          (ele) => ele?.raw_material_id == row?._id
                        )?.[0]?.total_item_amount
                      : "--"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {RawAvlData.length === 0 && (
            <Typography
              sx={{
                width: "100%",
                textAlign: "center",
                height: "100px",
                p: 5,
              }}
              variant="h5"
            >
              No Data Found
            </Typography>
          )}
        </TableContainer>
        <Pagination
          size="medium"
          count={totalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
            py: 1,
          }}
        />
      </DialogContent>
      <DialogActions sx={{ textAlign: "center" }}>
        <Button
          variant="contained"
          disabled={balanceQty == 0}
          onClick={() => {
            if (balanceQty > rowData?.balance_quantity) {
              Swal.fire({
                position: "center",
                icon: "warning",
                text: "Dispatching More than Required Quantity",
                customClass: {
                  container: "popupImportant",
                },
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              const previous = [...dispatchedDetails];
              previous?.length === 0
                ? (previous[indexNo] = dispatchedQty)
                : previous.splice(indexNo, 1, dispatchedQty);
              setDispatchedDetails(previous);
              setOpen(false);
            }
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

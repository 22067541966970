import Div from "@jumbo/shared/Div";
import {
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";

export const RawInventoryModal = ({ open, setOpen, rowData, tab }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth>
      {/* <Div
        sx={{
          display: "flex",
          minWidth: 600,
        }}
      >
        <DialogTitle sx={{ fontWeight: "700" }}>
          <Div>
            <Typography variant="h3" color="initial" fontWeight={600}>
              Item Details:-
            </Typography>
          </Div>
        </DialogTitle>
      </Div> */}
      <DialogContent>
        <Div sx={{ mb: 2 }}>
          <Typography variant="h3" color="initial" fontWeight={600}>
            Item Details
          </Typography>
        </Div>
        <Div
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 3,
            "& > *": {
              width: "100%",
            },
          }}
        >
          {/* <PattasTable
            data={rowData.item_received_quantities}
            heading={"Received Quantity"}
          /> */}
          {/* {tab !== "History" && (
            <>
              <PattasTable
                data={rowData.item_available_quantities}
                heading={"Available Quantity"}
              />
              <PattasTable
                data={rowData.item_rejected_quantities}
                heading={"Rejected Quantity"}
              />
            </>
          )} */}
          <Div>
            <Table size="small">
              <TableHead>
                <TableRow
                  sx={{
                    bgcolor: "#7352C7",
                    color: "white",
                    "& .MuiTableCell-root": {
                      // py: 2,
                    },
                  }}
                >
                  <TableCell sx={{ color: "white" }}>Received Pattas</TableCell>
                  <TableCell sx={{ color: "white" }}>
                    Available Pattas
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>Rejected Pattas</TableCell>
                  {/* <TableCell sx={{ color: "white" }}>Total</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ bgcolor: "#EDEBEB" }}>
                  <TableCell sx={{ textAlign: "left" }}>
                    {rowData?.item_received_pattas}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {rowData?.item_available_pattas}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {rowData?.item_rejected_pattas}
                  </TableCell>
                  {/* <TableCell>{total}</TableCell> */}
                </TableRow>
              </TableBody>
            </Table>
          </Div>
        </Div>
      </DialogContent>
    </Dialog>
  );
};

import Div from "@jumbo/shared/Div";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

export const GroupBundlesModal = ({ open, setOpen, rowData }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={1000}>
      <Div
        sx={{
          display: "flex",
          minWidth: 600,
        }}
      >
        <DialogTitle sx={{ fontWeight: "700" }}>
          <Div>
            <Typography variant="h3" mb={0} color="initial" fontWeight={600}>
              Group Bundles Details:-
            </Typography>
          </Div>
        </DialogTitle>
      </Div>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow sx={{
              bgcolor: "#7352C7", color: "white", "& .MuiTableCell-root": {
                py: 2,
              },
            }}>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "145px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Item Name
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "120px",
                    verticalAlign: "middle",
                    color: "white",
                    px: 1,
                  }}
                >
                  Item Type
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "90px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Log No
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "108px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Bundle No
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "90px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Length (cm)
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "70px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Width (cm)
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "115px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Original Pattas
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Original Sq.m.
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "105px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Avl Pattas
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Avl Total Sq.m.
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "75px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Grade
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "110px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Pallet No
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                    px: 1,
                  }}
                >
                  Physical Location
                </TableCell>
                {/* <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "85px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Avl Natural
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "85px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Avl Dyed
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Avl Smoked
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "95px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Total
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData?.item_details?.map((row, i) => (
                <TableRow key={i}>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.item_name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.item_code}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.item_log_no}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.item_bundle_no}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.item_length}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.item_width}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.item_received_pattas}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.item_received_sqm}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.item_available_pattas}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.item_available_sqm}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.item_grade}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.item_pallete_no}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.item_physical_location}
                  </TableCell>
                  {/* <TableCell
                    sx={{
                      textAlign: "left",
                      px: 1,
                    }}
                  >
                    {row?.item_available_quantities?.natural}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      px: 1,
                    }}
                  >
                    {row?.item_available_quantities?.dyed}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      px: 1,
                    }}
                  >
                    {row?.item_available_quantities?.smoked}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      px: 1,
                    }}
                  >
                    {row?.item_available_quantities?.total}
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

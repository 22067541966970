export const totalRevenue = [
    {month: 'Jan', amount: 1000},
    {month: 'Feb', amount: 850},
    {month: 'Mar', amount: 1400},
    {month: 'Apr', amount: 700},
    {month: 'May', amount: 1100},
    {month: 'Jun', amount: 900},
    {month: 'Jul', amount: 1600},
    {month: 'Aug', amount: 900},
    {month: 'Sep', amount: 1250},
    {month: 'Oct', amount: 1000},
    {month: 'Nov', amount: 1400},
    {month: 'Dec', amount: 1800},
];

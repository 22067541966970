import Div from "@jumbo/shared/Div/Div";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AllApis from "app/Apis";
import dayjs from "dayjs";
import { ErrorMessage, Form, Formik } from "formik";
import { Axios } from "index";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { FetchGroupNoQtyModal } from "./Modal/fetchGroupNoQtyModal";

export default function CreateGroupDispatch() {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();

  const [isSubmitting, setSubmitting] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [selectedIndexNo, setSelectedIndexNo] = useState(0);
  const [rowData, setRowData] = useState({});
  const [itemNameList, setItemNameList] = useState([]);
  const [itemCodeList, setItemCodeList] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [dispatchedDetails, setDispatchedDetails] = useState([]);
  //curent date
  const currentDate = new Date();
  currentDate.setUTCHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0
  const isoDate = currentDate.toISOString();
  const [dispatchDate, setDispatchDate] = useState(isoDate);
  const [loader, setLoader] = useState({
    addProduct: false,
    removeProduct: false,
  });

  const disabledStyling = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#475259",
    },
    "& .MuiInputLabel-root": {
      color: "#475259", // Change label color to blue
    },
  };

  const handleDispatchQty = (row, i) => {
    setOpen(true);
    setRowData(row);
    setSelectedIndexNo(i);
  };

  const getGroupNoBasedOnNameAndCode = async (
    data,
    index,
    setFieldValue,
    name,
    value
  ) => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    if (
      name == "order_item_name" &&
      data?.group_order_details?.[index]?.order_item_code !== ""
    ) {
      const groupList = await Axios.get(
        `${AllApis.order.groupNoBasedOnItemAndCode(
          value,
          data?.group_order_details?.[index]?.order_item_code
        )}`,
        config
      );
      setFieldValue(
        `group_order_details[${index}].groupList`,
        groupList?.data?.result
      );
    } else if (
      name == "order_item_code" &&
      data?.group_order_details?.[index]?.order_item_name !== ""
    ) {
      const groupList = await Axios.get(
        `${AllApis.order.groupNoBasedOnItemAndCode(
          data?.group_order_details?.[index]?.order_item_name,
          value
        )}`,
        config
      );
      setFieldValue(
        `group_order_details[${index}].groupList`,
        groupList?.data?.result
      );
    }
  };

  const handleSubmit = async (values) => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const dispatchedData = dispatchedDetails?.filter(
      (ele) => ele?.dispatch?.length != 0 && ele != undefined
    );
    for (let i = 0; i < dispatchedData.length; i++) {
      // Filter out null values from the dispatch array for each object
      dispatchedData[i].dispatch = dispatchedData[i].dispatch.filter(
        (item) => item !== null && item !== undefined
      );
    }
    const totalDispatchedAmt = dispatchedData.reduce(
      (total, item) => total + item.total_amount,
      0
    );
    const body = {
      order_id: state?._id,
      group_dispatch_details: dispatchedData.map((ele, i) => ({
        ...ele,
        item_remarks: values?.group_order_details?.[i]?.item_remarks || "", // Assuming item_remarks could be missing
      })),
      dispatched_date: dispatchDate,
      total_amount: totalDispatchedAmt,
      invoice_no: values?.invoice_no,
      dispatch_remarks: values?.dispatch_remarks,
    };
    if (dispatchedData?.length > 0) {
      try {
        setSubmitting(true);
        const response = await Axios.post(
          AllApis.dispatch.createDispatch,
          body,
          config
        );
        if (response?.status == 200) {
          Swal.fire({
            title: "Group Dispatch Created",
            icon: "success",
            timer: 1000,
            showConfirmButton: false,
          });
          navigate("/dashboard/dispatch");
        }
      } catch (error) {
        Swal.fire({
          title: error?.response?.data.message,
          icon: "error",
          timer: 1000,
          showConfirmButton: false,
        });
      } finally {
        setSubmitting(false);
      }
    } else {
      Swal.fire({
        title: "There is No Item To Dispatch",
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    }
  };

  const groupOrderSchema = yup.object().shape({
    invoice_no: yup.string().required("Invoice No is required"),
  });

  useEffect(() => {
    //For initial groupNo List for each item
    const fetchData = async () => {
      const updatedGroupOrderDetails = await Promise.all(
        state?.group_order_details?.map(async (ele, index) => {
          if (ele?.order_item_name !== "" && ele?.order_item_code !== "") {
            const config = {
              withCredentials: true,
              headers: {
                withCredentials: true,
              },
            };
            const groupList = await Axios.get(
              `${AllApis.order.groupNoBasedOnItemAndCode(
                ele?.order_item_name,
                ele?.order_item_code
              )}`
            );
            return {
              ...ele,
              groupList: groupList?.data?.result,
            };
          } else {
            return {
              ...ele,
              groupList: [],
            };
          }
        })
      );

      const updatedState = {
        ...state,
        invoice_no: "",
        dispatch_remarks: "",
        disableRow: state?.group_order_details.map((ele) => ({
          [ele?.item_no]:
            ele?.order_status === "closed" ||
            ele?.order_dispatched_pcs_qty !== 0,
        })),
        group_order_details: updatedGroupOrderDetails,
      };

      // Update the state with the computed data
      setInitialValues(updatedState);
    };

    fetchData();
  }, [state]);

  useEffect(async () => {
    try {
      const customerList = await Axios.get(
        `${AllApis.dropdownList.customer_name}`
      );
      setCustomerList(customerList?.data?.result);
      const itemNameList = await Axios.get(`${AllApis.dropdownList.item_name()}`);
      setItemNameList(itemNameList?.data?.result);

      const itemCodeList = await Axios.get(`${AllApis.dropdownList.item_code}`);
      setItemCodeList(itemCodeList?.data?.result);
    } catch (error) {}
  }, []);

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">Create Group Dispatch</Typography>
      <Div>
        <Formik
          validateOnChange={false}
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={groupOrderSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, errors, initialValues }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4, width: "100%" }}>
                <Div
                  sx={{
                    display: "flex",
                    width: "100%",
                    columnGap: 3,
                    rowGap: 2,
                    flexWrap: "wrap",
                  }}
                >
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Dispatched Date</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{
                          width: "100%",
                          ...disabledStyling,
                          "& .MuiInputBase-input": {
                            padding: 1,
                          },
                        }}
                        maxDate={dayjs()}
                        format="DD-MM-YYYY"
                        defaultValue={dayjs(dispatchDate)}
                        onChange={(newValue) => {
                          setDispatchDate(
                            newValue
                              .startOf("day")
                              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                          );
                        }}
                      />
                    </LocalizationProvider>
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Order Date</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disabled
                        sx={{
                          width: "100%",
                          ...disabledStyling,
                          "& .MuiInputBase-input": {
                            padding: 1,
                          },
                        }}
                        format="DD-MM-YYYY"
                        defaultValue={
                          values?.orderDate !== ""
                            ? dayjs(values?.orderDate)
                            : null
                        }
                        onChange={(newValue) => {
                          setFieldValue(
                            "orderDate",
                            newValue
                              .startOf("day")
                              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                          );
                        }}
                      />
                    </LocalizationProvider>
                  </Div>
                  <Div
                    sx={{
                      width: "20%",
                      display: "flex",
                      alignItems: "center",
                      columnGap: 1,
                    }}
                  >
                    <Div sx={{ width: "100%" }}>
                      <Typography variant="h5">Order No.</Typography>
                      <TextField
                        disabled
                        sx={{ width: "100%", ...disabledStyling }}
                        size="small"
                        value={values?.order_no}
                        name="order_no"
                      />
                    </Div>
                  </Div>

                  <Div
                    sx={{
                      width: "20%",
                      display: "flex",
                      alignItems: "center",
                      columnGap: 1,
                    }}
                  >
                    <Div sx={{ width: "100%" }}>
                      <Typography variant="h5">Purchase Order No</Typography>
                      <TextField
                        disabled
                        sx={{ width: "100%", ...disabledStyling }}
                        size="small"
                        onChange={(e) =>
                          setFieldValue("purchase_order_no", e.target.value)
                        }
                        value={values.purchase_order_no}
                        name="purchase_order_no"
                      />
                    </Div>
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Order Mode</Typography>
                    <Select
                      disabled
                      key={values?.order_mode}
                      sx={{ width: "100%", ...disabledStyling }}
                      size="small"
                      name="order_mode"
                      value={values?.order_mode}
                      onChange={(e) => {
                        setFieldValue("order_mode", e.target.value);
                      }}
                    >
                      <MenuItem value={"whatsapp"}>Whatsapp</MenuItem>
                      <MenuItem value={"offline"}>Offline</MenuItem>
                    </Select>
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Customer Name</Typography>
                    <Autocomplete
                      key={values?.customer_name}
                      size="small"
                      id="tags-standard"
                      options={customerList || []}
                      name="customer_name"
                      defaultValue={values?.customer_name}
                      isOptionEqualToValue={(option, value) =>
                        option.supplier_name === value
                      }
                      getOptionLabel={(option) => option}
                      onChange={(e, newValue) => {
                        if (newValue != null) {
                          setFieldValue(
                            "customer_name",
                            newValue?.supplier_name
                          );
                          setFieldValue("place", newValue?.city);
                        } else {
                          setFieldValue("customer_name", "");
                          setFieldValue("place", "");
                        }
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option?.supplier_name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                    <Div sx={{ height: "30px" }}>
                      <ErrorMessage
                        name="customer_name"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Customer Place</Typography>
                    <TextField
                      disabled
                      sx={{ width: "100%", ...disabledStyling }}
                      size="small"
                      value={values?.place}
                      name="place"
                    />
                    <Div sx={{ height: "30px" }}>
                      <ErrorMessage
                        name="place"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Invoice No</Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      onChange={(e) =>
                        setFieldValue("invoice_no", e.target.value)
                      }
                      value={values.invoice_no}
                      name="invoice_no"
                    />
                    <Div sx={{ height: "30px" }}>
                      <ErrorMessage
                        name="invoice_no"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                </Div>
              </Div>
              <Div sx={{ width: "100%", mt: 3 }}>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow sx={{
              bgcolor: "#7352C7", color: "white", "& .MuiTableCell-root": {
                py: 2,
              },
            }}>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "80px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Item No
                        </TableCell>
                        {/* <TableCell
                          sx={{
                            textAlign: "left",
                            width: "50px",
                            verticalAlign: "middle",
                            color: "white",
                            px: 1,
                          }}
                        >
                          Group No
                        </TableCell> */}

                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "100px",
                            verticalAlign: "middle",
                            color: "white",
                            px: 1,
                          }}
                        >
                          Group No.
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "350px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Item Name
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "150px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Item Type
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "100px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Length (cm)
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "100px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Width (cm)
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "100px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Required Pcs
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "100px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Dispatched Pcs
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "100px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Balance Pcs
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "120px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Required Sq.m.
                        </TableCell>

                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "100px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Rate Per Sq.m.
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "120px",
                            verticalAlign: "middle",
                            color: "white",
                            px: 1,
                          }}
                        >
                          Total Amount
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "300px",
                            verticalAlign: "middle",
                            color: "white",
                            px: 1,
                          }}
                        >
                          Remark
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "95px",
                            verticalAlign: "middle",
                            color: "white",
                            px: 1,
                            position: "sticky",
                            right: 0,
                            height: "58px",
                            zIndex: 1,
                            bgcolor: "#7352C7",
                          }}
                        >
                          Dispatch Qty
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {values?.group_order_details
                        ?.filter((ele) => ele?.order_status == "open")
                        ?.map((row, i) => {
                          return (
                            <TableRow key={i}>
                              <TableCell sx={{ textAlign: "left", px: 1 }}>
                                {row?.item_no}
                              </TableCell>
                              <TableCell sx={{ textAlign: "left", px: 1 }}>
                                <Autocomplete
                                  freeSolo
                                  disabled
                                  size="small"
                                  sx={{ width: "100%", ...disabledStyling }}
                                  id="tags-standard"
                                  options={row?.groupList || []}
                                  name="order_group_no"
                                  value={row?.order_group_no}
                                  isOptionEqualToValue={(option, value) =>
                                    option === value
                                  }
                                  getOptionLabel={(option) => option}
                                  onChange={(e, newValue) => {
                                    if (newValue != null) {
                                      setFieldValue(
                                        `group_order_details[${i}].order_group_no`,
                                        newValue
                                      );
                                    } else {
                                      setFieldValue(
                                        `group_order_details[${i}].order_group_no`,
                                        ""
                                      );
                                    }
                                  }}
                                  renderOption={(props, option) => (
                                    <Box
                                      component="li"
                                      sx={{
                                        "& > img": { mr: 2, flexShrink: 0 },
                                      }}
                                      {...props}
                                    >
                                      {option}
                                    </Box>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      sx={{
                                        width: "100%",
                                      }}
                                    />
                                  )}
                                />
                              </TableCell>
                              {/* <TableCell sx={{ textAlign: "left", px: 1 }}>
                                <Div
                                  onClick={() => {
                                    setOpen(true);
                                    setSelectedGroupNo(row?.order_group_no);
                                  }}
                                >
                                  <InfoIcon sx={{ cursor: "pointer" }} />
                                </Div>
                              </TableCell> */}
                              <TableCell sx={{ textAlign: "left", px: 1 }}>
                                <Autocomplete
                                  freeSolo
                                  disabled
                                  size="small"
                                  id="tags-standard"
                                  options={itemNameList || []}
                                  name="order_item_name"
                                  value={row?.order_item_name}
                                  isOptionEqualToValue={(option, value) =>
                                    option.item_name === value
                                  }
                                  getOptionLabel={(option) => {
                                    if (option instanceof Object) {
                                      return option.item_name;
                                    } else {
                                      return option;
                                    }
                                  }}
                                  onChange={(e, newValue) => {
                                    if (newValue != null) {
                                      setFieldValue(
                                        `group_order_details[${i}].order_item_name`,
                                        newValue?.item_name
                                      );
                                      getGroupNoBasedOnNameAndCode(
                                        values,
                                        i,
                                        setFieldValue,
                                        "order_item_name",
                                        newValue?.item_name
                                      );
                                    } else {
                                      setFieldValue(
                                        `group_order_details[${i}].order_item_name`,
                                        ""
                                      );
                                      setFieldValue(
                                        `group_order_details[${i}].groupList`,
                                        []
                                      );
                                    }
                                  }}
                                  renderOption={(props, option) => (
                                    <Box
                                      component="li"
                                      sx={{
                                        "& > img": { mr: 2, flexShrink: 0 },
                                      }}
                                      {...props}
                                    >
                                      {option?.item_name}
                                    </Box>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      sx={{
                                        width: "100%",
                                        ...disabledStyling,
                                      }}
                                    />
                                  )}
                                />
                              </TableCell>
                              <TableCell sx={{ textAlign: "left", px: 1 }}>
                                <Autocomplete
                                  freeSolo
                                  disabled
                                  size="small"
                                  id="tags-standard"
                                  options={itemCodeList || []}
                                  name="order_item_code"
                                  value={row?.order_item_code}
                                  isOptionEqualToValue={(option, value) =>
                                    option.item_code === value
                                  }
                                  getOptionLabel={(option) => {
                                    if (option instanceof Object) {
                                      return option.item_code;
                                    } else {
                                      return option;
                                    }
                                  }}
                                  onChange={(e, newValue) => {
                                    if (newValue != null) {
                                      setFieldValue(
                                        `group_order_details[${i}].order_item_code`,
                                        newValue?.item_code
                                      );
                                      getGroupNoBasedOnNameAndCode(
                                        values,
                                        i,
                                        setFieldValue,
                                        "order_item_code",
                                        newValue?.item_code
                                      );
                                    } else {
                                      setFieldValue(
                                        `group_order_details[${i}].order_item_code`,
                                        ""
                                      );
                                      setFieldValue(
                                        `group_order_details[${i}].groupList`,
                                        []
                                      );
                                    }
                                  }}
                                  renderOption={(props, option) => (
                                    <Box
                                      component="li"
                                      sx={{
                                        "& > img": { mr: 2, flexShrink: 0 },
                                      }}
                                      {...props}
                                    >
                                      {option?.item_code}
                                    </Box>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      sx={{
                                        width: "100%",
                                        ...disabledStyling,
                                      }}
                                    />
                                  )}
                                />
                              </TableCell>
                              <TableCell sx={{ textAlign: "left", px: 1 }}>
                                <TextField
                                  disabled
                                  type="number"
                                  sx={{ width: "100%", ...disabledStyling }}
                                  size="small"
                                  value={row?.order_length}
                                  name="order_length"
                                />
                              </TableCell>
                              <TableCell sx={{ textAlign: "left", px: 1 }}>
                                <TextField
                                  disabled
                                  type="number"
                                  sx={{ width: "100%", ...disabledStyling }}
                                  size="small"
                                  value={row?.order_width}
                                  name="order_width"
                                />
                              </TableCell>
                              <TableCell sx={{ textAlign: "left", px: 1 }}>
                                <TextField
                                  disabled
                                  type="number"
                                  sx={{ width: "100%", ...disabledStyling }}
                                  size="small"
                                  value={row?.order_required_pcs}
                                  name="order_required_pcs"
                                />
                              </TableCell>
                              <TableCell sx={{ textAlign: "left", px: 1 }}>
                                <TextField
                                  disabled
                                  type="number"
                                  sx={{ width: "100%", ...disabledStyling }}
                                  size="small"
                                  value={row?.order_dispatched_pcs_qty}
                                  name="order_dispatched_pcs_qty"
                                />
                              </TableCell>
                              <TableCell sx={{ textAlign: "left", px: 1 }}>
                                <TextField
                                  disabled
                                  type="number"
                                  sx={{ width: "100%", ...disabledStyling }}
                                  size="small"
                                  value={row?.order_balance_pcs_qty}
                                  name="order_balance_pcs_qty"
                                />
                              </TableCell>
                              <TableCell sx={{ textAlign: "left", px: 1 }}>
                                <TextField
                                  disabled
                                  sx={{ width: "100%", ...disabledStyling }}
                                  size="small"
                                  value={row?.order_required_sqm}
                                  name="order_required_sqm"
                                />
                              </TableCell>
                              <TableCell sx={{ textAlign: "left", px: 1 }}>
                                <TextField
                                  disabled
                                  type="number"
                                  sx={{ width: "100%", ...disabledStyling }}
                                  size="small"
                                  value={row?.order_rate}
                                  name="order_rate"
                                />
                              </TableCell>
                              <TableCell sx={{ textAlign: "left", px: 1 }}>
                                <TextField
                                  disabled
                                  sx={{ width: "100%", ...disabledStyling }}
                                  size="small"
                                  value={row?.total_order_amount}
                                  name="total_order_amount"
                                />
                              </TableCell>
                              <TableCell sx={{ textAlign: "left", px: 1 }}>
                                <TextField
                                  sx={{
                                    width: "100%",

                                    ...disabledStyling,
                                  }}
                                  size="small"
                                  value={row?.item_remarks}
                                  name="item_remarks"
                                  onChange={(e) =>
                                    setFieldValue(
                                      `group_order_details[${i}].item_remarks`,
                                      e.target.value
                                    )
                                  }
                                />
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "left",
                                  px: 1,
                                  position: "sticky",
                                  right: 0,
                                  zIndex: 1,
                                  bgcolor: "white",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  onClick={() => handleDispatchQty(row, i)}
                                >
                                  Add
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Div>
              <Div
                sx={{
                  width: "30%",
                  display: "flex",
                  alignItems: "center",
                  columnGap: 1,
                }}
              >
                <Div sx={{ width: "100%", mt: 2 }}>
                  <Typography variant="h5">Remark</Typography>
                  <TextField
                    sx={{ width: "100%", ...disabledStyling }}
                    size="small"
                    onChange={(e) =>
                      setFieldValue("dispatch_remarks", e.target.value)
                    }
                    value={values.dispatch_remarks}
                    name="dispatch_remarks"
                  />
                </Div>
              </Div>
              {/* {open && (
                <IssuedQuantityDetails
                  open={open}
                  setOpen={setOpen}
                  groupNo={selectedGroupNo}
                />
              )} */}
              
              {open && (
                <FetchGroupNoQtyModal
                  open={open}
                  setOpen={setOpen}
                  rowData={rowData}
                  indexNo={selectedIndexNo}
                  setDispatchedDetails={setDispatchedDetails}
                  dispatchedDetails={dispatchedDetails}
                />
              )}
              <Div
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 3,
                  mt: 5,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure you want to cancel?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes",
                      cancelButtonText: "No",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigate("/dashboard/dispatch");
                      }
                    });
                  }}
                >
                  Cancel
                </Button>

                <LoadingButton
                  loading={isSubmitting}
                  variant="contained"
                  type="submit"
                >
                  Create Dispatch
                </LoadingButton>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}

import Div from "@jumbo/shared/Div/Div";
import { LoadingButton } from "@mui/lab";
import { Button, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AllApis from "app/Apis";
import { Axios } from "index";
import { IssueForCuttingModal } from "../Modal/issueForCutting";

export default function IssueForCutting() {
  const [indexNo, setIndexNo] = useState(null);
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const navigate = useNavigate();
  const { state, pathname } = useLocation();

  const [rejectedBundles, setRejectedBundles] = useState({
    cutting_item_details: state?.item_details
      ?.filter((ele) => ele?.item_available_pattas !== 0)
      ?.map((row, i) => ({
        cutting_quantity: row?.item_available_pattas,
        // cutting_quantity: {
        //   natural: row?.item_available_quantities?.natural,
        //   dyed: row?.item_available_quantities?.dyed,
        //   smoked: row?.item_available_quantities?.smoked,
        //   total: row?.item_available_quantities?.total,
        // },
        group_sqm: 0,
        item_id: row?._id,
      })),
    total_item_sqm_original: state?.total_item_sqm_original,
    group_no_of_pattas_original: state?.group_no_of_pattas_original,
    group_no_of_pattas_available: 0,
    total_item_sqm_available: 0,
    cutting_issued_sqm: state?.total_item_sqm_available,
    total_issued_pattas: state?.group_no_of_pattas_available,
  });
  useEffect(() => {
    console.log(rejectedBundles, "rejectedBundles");
  }, [rejectedBundles]);
  const disabledStyling = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#475259",
    },
    "& .MuiInputLabel-root": {
      color: "#475259", // Change label color to blue
    },
  };

  const handleOnChange = (e, indexNo, rowData) => {
    const { name, value } = e.target;
    let quantity = rejectedBundles?.["cutting_item_details"]?.[indexNo]?.item_id
      ? rejectedBundles?.["cutting_item_details"]?.[indexNo]
      : {
          // cutting_quantity: {
          //   natural: 0,
          //   dyed: 0,
          //   smoked: 0,
          //   total: 0,
          // },
          cutting_quantity: 0,
          group_sqm: 0,
        };

    // let rejectedQty = {
    //   cutting_quantity: {
    //     ...quantity.cutting_quantity,
    //     [name]: Number(value),
    //   },
    // };
    let rejectedQty = {
      ...quantity,
      [name]: Number(value),
    };
    // const { natural, dyed, smoked } = rejectedQty.cutting_quantity;
    // const totalReceived =
    //   (parseFloat(natural) || 0) +
    //   (parseFloat(dyed) || 0) +
    //   (parseFloat(smoked) || 0);
    // rejectedQty.cutting_quantity.total = totalReceived;
    rejectedQty.group_sqm = parseFloat(
      (
        (rowData?.item_length *
          rowData?.item_width *
          (rowData?.item_available_pattas - rejectedQty.cutting_quantity)) /
        10000
      ).toFixed(2)
    );

    const rejectedArr = [...rejectedBundles.cutting_item_details];
    rejectedArr[indexNo] = {
      ...rejectedQty,
      item_id: rowData?._id,
    };

    //outside cutting_item_details
    const obj = {
      group_no_of_pattas_available: 0,
    };

    if (rejectedArr?.length > 0) {
      // const updatedOtherDetails = rejectedArr.reduce((acc, ele, i) => {
      //   acc.group_no_of_pattas_available +=
      //     ele?.["cutting_quantity"]?.["total"] || 0;
      //   return acc;
      // }, obj);

      const updatedOtherDetails = rejectedArr.reduce((acc, ele) => {
        acc.group_no_of_pattas_available += ele?.cutting_quantity;
        return acc;
      }, obj);

      const totalAvlItem = state?.item_details?.map((ele, i) => {
        if (ele?._id !== rejectedArr?.[i]?.item_id) {
          return {
            issuedQuantity: 0,
            totalavailableSqm: ele?.item_available_sqm,
          };
        } else {
          return {
            issuedQuantity: rejectedArr?.[i]?.cutting_quantity,
            totalavailableSqm: rejectedArr?.[i]?.group_sqm,
          };
        }
      });

      const total = totalAvlItem.reduce(
        (acc, ele, i) => {
          acc.total_item_sqm_available += ele?.totalavailableSqm;
          acc.total_issued_pattas += ele?.issuedQuantity;
          return acc;
        },
        {
          total_item_sqm_available: 0,
          total_issued_pattas: 0,
        }
      );

      setRejectedBundles({
        ...rejectedBundles,
        group_no_of_pattas_available:
          state?.group_no_of_pattas_available -
          updatedOtherDetails.group_no_of_pattas_available,
        total_item_sqm_available: parseFloat(
          total?.total_item_sqm_available
        ).toFixed(2),
        cutting_issued_sqm: parseFloat(
          (state?.total_item_sqm_available || 0) -
            (total?.total_item_sqm_available || 0)
        ).toFixed(2),
        total_issued_pattas: total?.total_issued_pattas,
        cutting_item_details: rejectedArr,
      });
    }
  };

  const handleSubmit = async () => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const body = {
      group_id: state?._id,
      ...rejectedBundles,
      cutting_item_details: rejectedBundles?.cutting_item_details.filter(
        (ele) => ele?.cutting_quantity != 0 && ele != null
      ),
    };
    if (body?.cutting_item_details?.length == 0) {
      return Swal.fire({
        title: "Please Select Pattas",
        icon: "warning",
        timer: 1000,
        showConfirmButton: false,
      });
    }
    try {
      setSubmitting(true);
      const response = await Axios.post(
        AllApis.grouping.issueForCutting,
        body,
        config
      );
      if (response?.data?.message) {
        Swal.fire({
          title: "Issued For Cutting Added Successful",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("/dashboard/grouping");
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setSubmitting(false);
    }
  };

  // useEffect(() => {
  //   const obj = {
  //     group_no_of_pattas_available: 0,
  //   };
  //   if (rejectedBundles?.["cutting_item_details"]?.length > 0) {
  //     const updatedOtherDetails = rejectedBundles?.[
  //       "cutting_item_details"
  //     ].reduce((acc, ele, i) => {
  //       acc.group_no_of_pattas_available +=
  //         ele?.["cutting_quantity"]?.["total"] || 0;
  //       return acc;
  //     }, obj);

  //     const totalAvlItem = state?.item_details?.map((ele, i) => {
  //       if (
  //         ele?._id !== rejectedBundles?.["cutting_item_details"]?.[i]?.item_id
  //       ) {
  //         return {
  //           issuedQuantity: 0,
  //           totalavailableSqm: ele?.item_available_sqm,
  //         };
  //       } else {
  //         return {
  //           issuedQuantity:
  //             rejectedBundles?.["cutting_item_details"]?.[i]?.[
  //               "cutting_quantity"
  //             ].total,
  //           totalavailableSqm:
  //             rejectedBundles?.["cutting_item_details"]?.[i]?.group_sqm,
  //         };
  //       }
  //     });
  //     const total = totalAvlItem.reduce(
  //       (acc, ele, i) => {
  //         acc.total_item_sqm_available += ele?.totalavailableSqm;
  //         acc.total_issued_pattas += ele?.issuedQuantity;
  //         return acc;
  //       },
  //       {
  //         total_item_sqm_available: 0,
  //         total_issued_pattas: 0,
  //       }
  //     );
  //     setRejectedBundles({
  //       ...rejectedBundles,
  //       group_no_of_pattas_available:
  //         state?.group_no_of_pattas_available -
  //         updatedOtherDetails.group_no_of_pattas_available,
  //       total_item_sqm_available: parseFloat(
  //         total?.total_item_sqm_available
  //       ).toFixed(2),
  //       total_issued_pattas: total?.total_issued_pattas,
  //     });
  //   }
  // }, [open]);

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1" sx={{ mb: 4 }}>
        Issue For Cutting
      </Typography>
      <Div>
        <Div sx={{ width: "100%" }}>
          <TableContainer component={Paper} sx={{ mb: 1 }}>
            <Table size="small">
              <TableHead>
                <TableRow
                  sx={{
                    bgcolor: "#7352C7",
                    color: "white",
                    "& .MuiTableCell-root": {
                      py: 2,
                    },
                  }}
                >
                  <TableCell sx={{ color: "white" }}>Group No.</TableCell>
                  <TableCell sx={{ color: "white" }}>Item Name</TableCell>
                  <TableCell sx={{ color: "white" }}>Item Type</TableCell>
                  <TableCell sx={{ color: "white" }}>Group Pcs</TableCell>
                  <TableCell sx={{ color: "white" }}>Grade</TableCell>
                  <TableCell sx={{ color: "white" }}>Pallet No.</TableCell>
                  <TableCell sx={{ color: "white" }}>
                    Physical Location
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{state?.group_no}</TableCell>
                  <TableCell>{state?.item_details?.[0]?.item_name}</TableCell>
                  <TableCell>{state?.item_details?.[0]?.item_code}</TableCell>
                  <TableCell>{state?.group_pcs}</TableCell>
                  <TableCell>{state?.group_grade}</TableCell>
                  <TableCell>{state?.group_pallete_no}</TableCell>
                  <TableCell>{state?.group_physical_location}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer component={Paper}>
            <Div sx={{ maxHeight: "44vh" }}>
              <Table size="small">
                <TableHead sx={{ position: "sticky", top: 0 }}>
                  <TableRow
                    sx={{
                      bgcolor: "#7352C7",
                      color: "white",
                      "& .MuiTableCell-root": {
                        py: 2,
                      },
                    }}
                  >
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "40px",
                        verticalAlign: "middle",
                        px: 1,
                        color: "white",
                      }}
                    >
                      Sr No.
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "100px",
                        verticalAlign: "middle",
                        px: 1,
                        color: "white",
                      }}
                    >
                      Log No
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "100px",
                        verticalAlign: "middle",
                        px: 1,
                        color: "white",
                      }}
                    >
                      Bundle No
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "100px",
                        verticalAlign: "middle",
                        px: 1,
                        color: "white",
                      }}
                    >
                      Length (cm)
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "100px",
                        verticalAlign: "middle",
                        px: 1,
                        color: "white",
                      }}
                    >
                      Width (cm)
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "100px",
                        verticalAlign: "middle",
                        px: 1,
                        color: "white",
                      }}
                    >
                      Avl Pattas
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "100px",
                        verticalAlign: "middle",
                        px: 1,
                        color: "white",
                      }}
                    >
                      Avl Total Sq.m.
                    </TableCell>

                    {/* {state?.item_details?.[0]?.item_code?.toLowerCase() ==
                      "natural" && (
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "100px",
                          verticalAlign: "middle",
                          px: 1,
                          color: "white",
                        }}
                      >
                        Avl Natural
                      </TableCell>
                    )}
                    {state?.item_details?.[0]?.item_code?.toLowerCase() ==
                      "dyed" && (
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "100px",
                          verticalAlign: "middle",
                          px: 1,
                          color: "white",
                        }}
                      >
                        Avl Dyed
                      </TableCell>
                    )}
                    {state?.item_details?.[0]?.item_code?.toLowerCase() ==
                      "smoked" && (
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "100px",
                          verticalAlign: "middle",
                          px: 1,
                          color: "white",
                        }}
                      >
                        Avl Smoked
                      </TableCell>
                    )} */}
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "100px",
                        verticalAlign: "middle",
                        px: 1,
                        color: "white",
                      }}
                    >
                      Issue Pattas
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "150px",
                        verticalAlign: "middle",
                        px: 1,
                        color: "white",
                      }}
                    >
                      Supplier
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state?.item_details
                    ?.filter(
                      (ele) => ele?.item_available_quantities?.total != 0
                    )
                    ?.map((row, i) => (
                      <TableRow key={i}>
                        <TableCell sx={{ textAlign: "left", px: 1 }}>
                          {i + 1}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left", px: 1 }}>
                          {row?.item_log_no}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left", px: 1 }}>
                          {row?.item_bundle_no}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left", px: 1 }}>
                          {row?.item_length}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left", px: 1 }}>
                          {row?.item_width}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left", px: 1 }}>
                          {row?.item_available_pattas}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left", px: 1 }}>
                          {row?.item_available_sqm}
                        </TableCell>

                        {/* {row?.item_available_quantities?.natural > 0 && (
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            {row?.item_available_quantities?.natural}
                          </TableCell>
                        )}
                        {row?.item_available_quantities?.dyed > 0 && (
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            {row?.item_available_quantities?.dyed}
                          </TableCell>
                        )} */}
                        {/* {row?.item_available_quantities?.smoked > 0 && (
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            {row?.item_available_quantities?.smoked}
                          </TableCell>
                        )} */}
                        {/* {row?.item_available_quantities?.natural > 0 && ( */}
                        <TableCell sx={{ width: "10%", px: 1 }}>
                          {" "}
                          <TextField
                            size="small"
                            name="cutting_quantity"
                            type="number"
                            sx={{ width: "100%" }}
                            inputProps={{
                              min: 0,
                              max: row?.item_available_pattas,
                              // max: row?.item_available_quantities?.natural,
                            }}
                            onKeyDown={(e) => {
                              const inputValue = parseInt(
                                e.target.value + e.key,
                                10
                              );

                              if (
                                isNaN(inputValue) ||
                                inputValue < 0 ||
                                inputValue > row?.item_available_pattas
                                // row?.item_available_quantities?.natural
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => handleOnChange(e, i, row)}
                            defaultValue={
                              rejectedBundles?.cutting_item_details?.[i]
                                ?.cutting_quantity
                              // rejectedBundles?.cutting_item_details?.[i]
                              //   ?.cutting_quantity?.natural
                            }
                          />
                        </TableCell>
                        {/* )} */}

                        {/* {row?.item_available_quantities?.dyed > 0 && (
                          <TableCell sx={{ width: "10%", px: 1 }}>
                            {" "}
                            <TextField
                              size="small"
                              name="dyed"
                              type="number"
                              sx={{ width: "100%" }}
                              inputProps={{
                                min: 0,
                                max: row?.item_available_quantities?.dyed,
                              }}
                              onKeyDown={(e) => {
                                const inputValue = parseInt(
                                  e.target.value + e.key,
                                  10
                                );

                                if (
                                  isNaN(inputValue) ||
                                  inputValue < 0 ||
                                  inputValue >
                                    row?.item_available_quantities?.dyed
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => handleOnChange(e, i, row)}
                              defaultValue={
                                rejectedBundles?.cutting_item_details?.[i]
                                  ?.cutting_quantity?.dyed
                              }
                            />
                          </TableCell>
                        )} */}
                        {/* {row?.item_available_quantities?.smoked > 0 && (
                          <TableCell sx={{ width: "10%", px: 1 }}>
                            {" "}
                            <TextField
                              size="small"
                              name="smoked"
                              type="number"
                              sx={{ width: "100%" }}
                              inputProps={{
                                min: 0,
                                max: row?.item_available_quantities?.smoked,
                              }}
                              onKeyDown={(e) => {
                                const inputValue = parseInt(
                                  e.target.value + e.key,
                                  10
                                );

                                if (
                                  isNaN(inputValue) ||
                                  inputValue < 0 ||
                                  inputValue >
                                    row?.item_available_quantities?.smoked
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => handleOnChange(e, i, row)}
                              defaultValue={
                                rejectedBundles?.cutting_item_details?.[i]
                                  ?.cutting_quantity?.smoked
                              }
                            />
                          </TableCell>
                        )} */}
                        <TableCell sx={{ textAlign: "left", px: 1 }}>
                          {row?.supplier_details?.supplier_name}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Div>
          </TableContainer>
        </Div>
        <Div sx={{ mt: 4, width: "100%" }}>
          <Div
            sx={{
              display: "flex",
              width: "100%",
              columnGap: 3,
              flexWrap: "wrap",
            }}
          >
            {/* <Div sx={{ width: "20%" }}>
              <Typography variant="h5">Group Length</Typography>
              <TextField
                disabled
                sx={{ width: "100%", ...disabledStyling }}
                size="small"
                value={state?.group_length}
                name="group_length"
              />
            </Div>
            <Div sx={{ width: "20%" }}>
              <Typography variant="h5">Group Width</Typography>
              <TextField
                disabled
                sx={{ width: "100%", ...disabledStyling }}
                size="small"
                value={state?.group_width}
                name="group_width"
              />
            </Div> */}
            <Div sx={{ width: "20%" }}>
              <Typography variant="h5">Balance No. of Pattas</Typography>
              <TextField
                disabled
                sx={{ width: "100%", ...disabledStyling }}
                size="small"
                value={rejectedBundles?.group_no_of_pattas_available}
                name="group_no_of_pattas_original"
              />
            </Div>
            <Div sx={{ width: "20%" }}>
              <Typography variant="h5">Issued No. of Pattas</Typography>
              <TextField
                sx={{ width: "100%", ...disabledStyling }}
                disabled
                size="small"
                value={rejectedBundles?.total_issued_pattas}
              />
            </Div>
            <Div sx={{ width: "20%" }}>
              <Typography variant="h5">Total Avl Item Sq.m.</Typography>
              <TextField
                sx={{ width: "100%", ...disabledStyling }}
                disabled
                size="small"
                value={rejectedBundles?.total_item_sqm_available}
                name="total_item_sqm_available"
              />
            </Div>
            <Div sx={{ width: "20%" }}>
              <Typography variant="h5">Issued Item Sq.m.</Typography>
              <TextField
                sx={{ width: "100%", ...disabledStyling }}
                disabled
                size="small"
                value={rejectedBundles?.cutting_issued_sqm}
                name="cutting_issued_sqm"
              />
            </Div>
          </Div>
        </Div>
        <Div
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 3,
            mt: 5,
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              Swal.fire({
                title: "Are you sure you want to cancel?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate("/dashboard/grouping");
                }
              });
            }}
          >
            Cancel
          </Button>

          <LoadingButton
            loading={isSubmitting}
            variant="contained"
            onClick={handleSubmit}
          >
            Add Issue For Cutting
          </LoadingButton>
        </Div>
      </Div>
      <IssueForCuttingModal
        open={open}
        setOpen={setOpen}
        rowData={rowData}
        indexNo={indexNo}
        rejectedBundles={rejectedBundles}
        setRejectedBundles={setRejectedBundles}
      />
    </Div>
  );
}

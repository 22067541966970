/* eslint-disable default-case */
import Div from "@jumbo/shared/Div";
import InfoIcon from "@mui/icons-material/Info";
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import FullScreenLoader from "app/components/ListingPageLoader";
import { CuttingBundleModal } from "app/pages/Grouping/Modal/cuttingBundleQty";
import { getCuttingDoneList } from "app/redux/actions/factoryAction";
import { displayDateFun } from "app/utils/constants/functions";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ViewImage from "../Modal/viewImage";

export default function CuttingDoneList({
  page,
  setPage,
  searchTerm,
  filter,
  sort,
  setSort,
  sortBy,
  setSortBy,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cuttingDone, error, TotalPage, loading } = useSelector(
    (state) => state.factoryReducer
  );
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState({});
  const [openView, setOpenView] = useState(false);

  const handleSort = (property) => {
    setSort(sort == "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getCuttingDoneList(searchTerm, sort, sortBy, newPage, filter));
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow sx={{
              bgcolor: "#7352C7", color: "white", "& .MuiTableCell-root": {
                // py: 2,
              },
            }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_history_id.group_id.group_no"}
                  direction={sort}
                  onClick={() =>
                    handleSort("group_history_id.group_id.group_no")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Group No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "250px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={
                    sortBy === "group_history_id.cutting_item_details.item_name"
                  }
                  direction={sort}
                  onClick={() =>
                    handleSort(
                      "group_history_id.cutting_item_details.item_name"
                    )
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={
                    sortBy === "group_history_id.cutting_item_details.item_code"
                  }
                  direction={sort}
                  onClick={() =>
                    handleSort(
                      "group_history_id.cutting_item_details.item_code"
                    )
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Type
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "165px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_history_id.group_id.group_length"}
                  direction={sort}
                  onClick={() =>
                    handleSort("group_history_id.group_id.group_length")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Group Length (cm)
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "165px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_history_id.group_id.group_width"}
                  direction={sort}
                  onClick={() =>
                    handleSort("group_history_id.group_id.group_width")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Group Width (cm)
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "108px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_history_id.group_id.group_pcs"}
                  direction={sort}
                  onClick={() =>
                    handleSort("group_history_id.group_id.group_pcs")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  No of Pcs
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "108px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={
                    sortBy ===
                    "group_history_id.group_id.group_no_of_pattas_available"
                  }
                  direction={sort}
                  onClick={() =>
                    handleSort(
                      "group_history_id.group_id.group_no_of_pattas_available"
                    )
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Avl. No of Pattas
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "90px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={
                    sortBy === "group_history_id.group_id.group_sqm_available"
                  }
                  direction={sort}
                  onClick={() =>
                    handleSort("group_history_id.group_id.group_sqm_available")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Group Sq.m.
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "108px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={
                    sortBy ===
                    "group_history_id.group_id.total_item_sqm_available"
                  }
                  direction={sort}
                  onClick={() =>
                    handleSort(
                      "group_history_id.group_id.total_item_sqm_available"
                    )
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Total Item Sq.m. Avl
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "70px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_history_id.group_id.group_grade"}
                  direction={sort}
                  onClick={() =>
                    handleSort("group_history_id.group_id.group_grade")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Grade
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "105px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_history_id.group_id.orientation"}
                  direction={sort}
                  onClick={() =>
                    handleSort("group_history_id.group_id.orientation")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Orientation
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_history_id.group_id.book_type"}
                  direction={sort}
                  onClick={() =>
                    handleSort("group_history_id.group_id.book_type")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Formation Type
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "75px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={
                    sortBy === "group_history_id.group_id.group_pallete_no"
                  }
                  direction={sort}
                  onClick={() =>
                    handleSort("group_history_id.group_id.group_pallete_no")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Group Pallet No
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "110px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={
                    sortBy ===
                    "group_history_id.group_id.group_physical_location"
                  }
                  direction={sort}
                  onClick={() =>
                    handleSort(
                      "group_history_id.group_id.group_physical_location"
                    )
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Physical Location
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "180px",
                  verticalAlign: "middle",
                  color: "white",
                  px: 1,
                }}
              >
                Remark
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "155px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Date of Cutting
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "85px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Cutting Details
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "85px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                View Images
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "115px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "created_employee_id.first_name"}
                  direction={sort}
                  onClick={() => handleSort("created_employee_id.first_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Created By
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cuttingDone?.map((row, i) => (
              <TableRow key={i}>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.group_history_id?.group_id?.group_no}
                </TableCell>

                <TableCell sx={{ textAlign: "left" }}>
                  {row?.group_history_id?.cutting_item_details?.[0]?.item_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.group_history_id?.cutting_item_details?.[0]?.item_code}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_history_id?.group_id?.group_length}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_history_id?.group_id?.group_width}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_history_id?.group_id?.group_pcs}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {
                    row?.group_history_id?.group_id
                      ?.group_no_of_pattas_available
                  }
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {parseFloat(
                    row?.group_history_id?.group_id?.group_sqm_available
                  ).toFixed(2)}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {parseFloat(
                    row?.group_history_id?.group_id?.total_item_sqm_available
                  ).toFixed(2)}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_history_id?.group_id?.group_grade}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {row?.group_history_id?.group_id?.orientation}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {row?.group_history_id?.group_id?.book_type}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_history_id?.group_id?.group_pallete_no}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {row?.group_history_id?.group_id?.group_physical_location}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.cutting_remarks ? row?.cutting_remarks : "-"}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {displayDateFun(row?.cutting_date)}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 2 }}>
                  <Div
                    onClick={() => {
                      setOpen(true);
                      setRowData(
                        row?.group_history_id?.cutting_item_details.map(
                          (ele, ind) => {
                            return {
                              ...ele,
                              ...row.item_details[ind],
                              cutting_quantity:
                                row.item_details[ind]?.final_cutting_quantity,
                            };
                          }
                        )
                      );
                    }}
                  >
                    <InfoIcon sx={{ cursor: "pointer" }} />
                  </Div>
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 2 }}>
                  <Div
                    onClick={() => {
                      setOpenView(true);
                      setRowData(row);
                    }}
                  >
                    <InfoIcon sx={{ cursor: "pointer" }} />
                  </Div>
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {row?.created_employee_id?.first_name}{" "}
                  {row?.created_employee_id?.last_name}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {open && (
            <CuttingBundleModal
              open={open}
              setOpen={setOpen}
              rowData={rowData}
            />
          )}
          {openView && (
            <ViewImage
              openView={openView}
              setOpenView={setOpenView}
              data={rowData}
            />
          )}
        </Table>
        <Pagination size="medium"
          count={TotalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
            py:1
          }}
        />
      </TableContainer>
    </>
  );
}

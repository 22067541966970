import {
  Dialog,
  DialogContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";

export const DispatchedGroupItemDetailsModal = ({ open, setOpen, rowData }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={1000}>
      <DialogContent>
        <Typography variant="h3" color="initial" fontWeight={600}>
          Dispatched Details (Order No : {rowData?.order_details?.order_no})
        </Typography>
        <TableContainer component={Paper}>
          <Table border={2.5}>
            <TableHead>
              <TableRow sx={{
              bgcolor: "#7352C7", color: "white", "& .MuiTableCell-root": {
                py: 2,
              },
            }}>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "80px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Item No
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "80px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Group No
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "350px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Item Name
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "150px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Item Type
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Required Pcs
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Dispatched Pcs
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Length (cm)
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Width (cm)
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "120px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Sq.m.
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Rate Per Sq.m.
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "120px",
                    verticalAlign: "middle",
                    color: "white",
                    px: 1,
                  }}
                >
                  Total Amount
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "300px",
                    verticalAlign: "middle",
                    color: "white",
                    px: 1,
                  }}
                >
                  Remark
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData?.group_dispatch_details?.map((ele, index) =>
                ele?.dispatch.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {ele?.item_no}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.qc_details?.group_no}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {
                        rowData?.order_details?.group_order_details?.filter(
                          (orderData) => orderData?.item_no == ele?.item_no
                        )[0]?.order_item_name
                      }
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {
                        rowData?.order_details?.group_order_details?.filter(
                          (orderData) => orderData?.item_no == ele?.item_no
                        )[0]?.order_item_code
                      }
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {
                        rowData?.order_details?.group_order_details?.filter(
                          (orderData) => orderData?.item_no == ele?.item_no
                        )[0]?.order_required_pcs
                      }
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.qc_dispatched_qty}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.qc_details?.qc_length}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.qc_details?.qc_width}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.dispatch_sqm}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {
                        rowData?.order_details?.group_order_details?.filter(
                          (orderData) => orderData?.item_no == ele?.item_no
                        )[0]?.order_rate
                      }
                    </TableCell>

                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.total_item_amount}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {ele?.item_remarks || "-"}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

import Div from "@jumbo/shared/Div/Div";
import InfoIcon from "@mui/icons-material/Info";
import RefreshIcon from "@mui/icons-material/Refresh";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  FormHelperText,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AllApis from "app/Apis";
import { getCurrentDate } from "app/components/Function/getCurrentDate";
import dayjs from "dayjs";
import { ErrorMessage, Form, Formik } from "formik";
import { Axios } from "index";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { IssuedQuantityDetails } from "../Modal/issuedQuantityModal";

export default function CreateGroupOrder() {
  const [isSubmitting, setSubmitting] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [selectedGroupNo, setSelectedGroupNo] = useState(0);
  const [itemNameList, setItemNameList] = useState([]);
  const [itemCodeList, setItemCodeList] = useState([]);
  const [orderNo, setOrderNo] = useState(0);
  const [loader, setLoader] = useState({
    addProduct: false,
    removeProduct: false,
  });

  const disabledStyling = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#475259",
    },
    "& .MuiInputLabel-root": {
      color: "#475259", // Change label color to blue
    },
  };
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const [selectedBundles, setSelectedBundles] = useState(state);

  const initialValues = {
    purchase_order_no: "",
    order_no: "",
    order_type: "group",
    customer_name: "",
    place: "",
    order_mode: "",
    order_status: "pending",
    group_order_details: [
      {
        item_remarks: "",
        item_no: "",
        order_group_no: "",
        order_item_name: "",
        order_item_code: "",
        order_length: "",
        order_width: "",
        order_required_pcs: "",
        order_required_sqm: "",
        order_rate: "",
        total_order_amount: "",
        order_status: "pending",
        groupList: [],
      },
    ],
    order_remarks: "",
    orderDate: getCurrentDate(),
  };

  const groupOrderSchema = yup.object().shape({
    purchase_order_no: yup.string().required("Purchase Order No is required"),
    order_type: yup.string().required("Order Type is required"),
    customer_name: yup.string().required("Customer Name is required"),
    place: yup.string().required("Place is required"),
    order_mode: yup.string().required("Order Mode is required"),
    order_status: yup.string().required("Order Status is required"),
    group_order_details: yup.array().of(
      yup.object().shape({
        // order_group_no: yup.string().required("Order Group No  is required"),
        order_item_name: yup.string().required("Order Item Name is required"),
        order_item_code: yup.string().required("Order Item Type is required"),
        order_length: yup
          .number()
          .required("Order Length is required")
          .positive()
          .integer(),
        order_width: yup
          .number()
          .required("Order Width is required")
          .positive()
          .integer(),
        order_required_pcs: yup
          .number()
          .required("Order Required Pcs is required")
          .positive()
          .integer(),
        order_required_sqm: yup
          .number()
          .required("Order Required Sq.m. is required")
          .positive(),
        order_rate: yup.number().required("Order Rate is required").positive(),
        total_order_amount: yup
          .number()
          .required("Total Order Amount is required")
          .positive(),
        order_status: yup.string().required("Order Status is required"),
      })
    ),
    orderDate: yup.date().required("Order Date is required"),
  });

  const calculateSqm = (e, setFieldValue, data, index) => {
    const { name, value } = e.target;
    if (name == "order_length") {
      const sqm =
        (Number(value) *
          (Number(data.order_width) || 0) *
          (Number(data?.order_required_pcs) || 0)) /
        10000;
      const amtTotal = (Number(data?.order_rate) || 0) * sqm;
      setFieldValue(
        `group_order_details[${index}].total_order_amount`,
        amtTotal?.toFixed(2)
      );
      setFieldValue(
        `group_order_details[${index}].order_required_sqm`,
        sqm?.toFixed(2)
      );
    } else if (name == "order_width") {
      const sqm =
        (Number(value) *
          (Number(data.order_length) || 0) *
          (Number(data?.order_required_pcs) || 0)) /
        10000;
      const amtTotal = (Number(data?.order_rate) || 0) * sqm;
      setFieldValue(
        `group_order_details[${index}].total_order_amount`,
        amtTotal?.toFixed(2)
      );
      setFieldValue(
        `group_order_details[${index}].order_required_sqm`,
        sqm?.toFixed(2)
      );
    } else if (name == "order_required_pcs") {
      const sqm =
        ((Number(data.order_length) || 0) *
          (Number(data.order_width) || 0) *
          Number(value)) /
        10000;
      const amtTotal = (Number(data?.order_rate) || 0) * sqm;
      setFieldValue(
        `group_order_details[${index}].total_order_amount`,
        amtTotal?.toFixed(2)
      );
      setFieldValue(
        `group_order_details[${index}].order_required_sqm`,
        sqm?.toFixed(2)
      );
    } else if (name == "order_rate") {
      const total = (Number(data["order_required_sqm"]) || 0) * Number(value);
      setFieldValue(
        `group_order_details[${index}].total_order_amount`,
        total?.toFixed(2)
      );
    }
    setFieldValue(`group_order_details[${index}][${name}]`, value);
  };
  const removeRow = (values, setFieldValue, index) => {
    setLoader({ ...loader, removeProduct: true });
    setTimeout(() => {
      const updateData = [...values["group_order_details"]];
      updateData.splice(index, 1);
      setFieldValue("group_order_details", updateData);
      setLoader({ ...loader, removeProduct: false });
    }, 200);
  };

  const getGroupNoBasedOnNameAndCode = async (
    data,
    index,
    setFieldValue,
    name,
    value
  ) => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    if (
      name == "order_item_name" &&
      data?.group_order_details?.[index]?.order_item_code !== ""
    ) {
      const groupList = await Axios.get(
        `${AllApis.order.groupNoBasedOnItemAndCode(
          value,
          data?.group_order_details?.[index]?.order_item_code
        )}`,
        config
      );
      setFieldValue(
        `group_order_details[${index}].groupList`,
        groupList?.data?.result
      );
    } else if (
      name == "order_item_code" &&
      data?.group_order_details?.[index]?.order_item_name !== ""
    ) {
      const groupList = await Axios.get(
        `${AllApis.order.groupNoBasedOnItemAndCode(
          data?.group_order_details?.[index]?.order_item_name,
          value
        )}`,
        config
      );
      setFieldValue(
        `group_order_details[${index}].groupList`,
        groupList?.data?.result
      );
    }
  };

  const handleSubmit = async (values) => {
    const body = {
      ...values,
      order_no: orderNo,
      group_order_details: values?.group_order_details.map((ele, i) => {
        return {
          ...ele,
          item_no: i + 1,
          order_status: ele?.order_group_no != "" ? "open" : "pending",
        };
      }),
    };
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    try {
      setSubmitting(true);
      const response = await Axios.post(AllApis.order.create, body, config);
      if (response?.status == 201) {
        Swal.fire({
          title: "Group Order Created",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("/dashboard/orders");
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(async () => {
    try {
      const customerList = await Axios.get(
        `${AllApis.dropdownList.customer_name}`
      );
      setCustomerList(customerList?.data?.result);
      const itemNameList = await Axios.get(`${AllApis.dropdownList.item_name()}`);
      setItemNameList(itemNameList?.data?.result);

      const itemCodeList = await Axios.get(`${AllApis.dropdownList.item_code}`);
      setItemCodeList(itemCodeList?.data?.result);
    } catch (error) {}
  }, []);

  useEffect(async () => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const orderNO = await Axios.get(`${AllApis.dropdownList.order_no}`, config);
    setOrderNo(orderNO?.data?.latestOrderNo);
  }, [refresh]);

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">Create Group Order</Typography>
      <Div>
        <Formik
          validateOnChange={false}
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={groupOrderSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, errors, initialValues }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4, width: "100%" }}>
                <Div
                  sx={{
                    display: "flex",
                    width: "100%",
                    columnGap: 3,
                    flexWrap: "wrap",
                  }}
                >
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Order Date</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-input": {
                            padding: 1,
                          },
                        }}
                        format="DD-MM-YYYY"
                        defaultValue={
                          values?.orderDate !== ""
                            ? dayjs(values?.orderDate)
                            : null
                        }
                        onChange={(newValue) => {
                          setFieldValue(
                            "orderDate",
                            newValue
                              .startOf("day")
                              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                          );
                        }}
                      />
                      <Div sx={{ height: "30px" }}>
                        <ErrorMessage
                          name="orderDate"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </LocalizationProvider>
                  </Div>
                  <Div
                    sx={{
                      width: "20%",
                      display: "flex",
                      alignItems: "center",
                      columnGap: 1,
                    }}
                  >
                    <Div sx={{ width: "90%" }}>
                      <Typography variant="h5">Order No.</Typography>
                      <TextField
                        disabled
                        sx={{ width: "100%", ...disabledStyling }}
                        size="small"
                        value={orderNo}
                        name="order_no"
                      />
                      <Div sx={{ height: "30px" }}>
                        <ErrorMessage
                          name="order_no"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </Div>

                    <RefreshIcon
                      sx={{ cursor: "pointer", width: "10%" }}
                      onClick={() => setRefresh(!refresh)}
                    />
                  </Div>

                  <Div
                    sx={{
                      width: "20%",
                      display: "flex",
                      alignItems: "center",
                      columnGap: 1,
                    }}
                  >
                    <Div sx={{ width: "90%" }}>
                      <Typography variant="h5">Purchase Order No*</Typography>
                      <TextField
                        sx={{ width: "100%", ...disabledStyling }}
                        size="small"
                        onChange={(e) =>
                          setFieldValue("purchase_order_no", e.target.value)
                        }
                        value={values.purchase_order_no}
                        name="purchase_order_no"
                      />
                      <Div sx={{ height: "30px" }}>
                        <ErrorMessage
                          name="purchase_order_no"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </Div>
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Order Mode*</Typography>
                    <Select
                      sx={{ width: "100%" }}
                      size="small"
                      name="order_mode"
                      value={values?.order_mode}
                      onChange={(e) => {
                        setFieldValue("order_mode", e.target.value);
                      }}
                    >
                      <MenuItem value={"whatsapp"}>Whatsapp</MenuItem>
                      <MenuItem value={"offline"}>Offline</MenuItem>
                    </Select>
                    <Div sx={{ height: "30px" }}>
                      <ErrorMessage
                        name="order_mode"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Customer Name*</Typography>
                    <Autocomplete
                      freeSolo
                      size="small"
                      id="tags-standard"
                      options={customerList || []}
                      name="customer_name"
                      value={values?.customer_name}
                      isOptionEqualToValue={(option, value) =>
                        option.supplier_name === value
                      }
                      getOptionLabel={(option) => option}
                      onChange={(e, newValue) => {
                        if (newValue != null) {
                          setFieldValue(
                            "customer_name",
                            newValue?.supplier_name
                          );
                          setFieldValue("place", newValue?.city);
                        } else {
                          setFieldValue("customer_name", "");
                          setFieldValue("place", "");
                        }
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option?.supplier_name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                    <Div sx={{ height: "30px" }}>
                      <ErrorMessage
                        name="customer_name"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Customer Place*</Typography>
                    <TextField
                      disabled
                      sx={{ width: "100%", ...disabledStyling }}
                      size="small"
                      value={values?.place}
                      name="place"
                    />
                    <Div sx={{ height: "30px" }}>
                      <ErrorMessage
                        name="place"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                </Div>
              </Div>
              <Div sx={{ width: "100%", mt: 3 }}>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow
                        sx={{
                          bgcolor: "#7352C7",
                          color: "white",
                          "& .MuiTableCell-root": {
                            py: 2,
                          },
                        }}
                      >
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "80px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Item No
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "50px",
                            verticalAlign: "middle",
                            color: "white",
                            px: 1,
                          }}
                        >
                          Group No
                        </TableCell>

                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "110px",
                            verticalAlign: "middle",
                            color: "white",
                            px: 1,
                          }}
                        >
                          Group Avl Qty
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "350px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Item Name
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "150px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Item Type
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "100px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Length (cm)
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "100px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Width (cm)
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "110px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Required Pcs
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "120px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Required Sq.m.
                        </TableCell>

                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "120px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Rate Per Sq.m.
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "120px",
                            verticalAlign: "middle",
                            color: "white",
                            px: 1,
                          }}
                        >
                          Total Amount
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "300px",
                            verticalAlign: "middle",
                            color: "white",
                            px: 1,
                          }}
                        >
                          Remark
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            width: "50px",
                            verticalAlign: "middle",
                            color: "white",
                            px: 1,
                            px: 1,
                            position: "sticky",
                            right: 0,
                            height: "58px",
                            zIndex: 1,
                            bgcolor: "#7352C7",
                          }}
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {values?.group_order_details?.map((row, i) => (
                        <TableRow key={i}>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            {i + 1}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            <Autocomplete
                              freeSolo
                              disabled={row?.groupList?.length == 0}
                              size="small"
                              sx={{ width: "100%" }}
                              id="tags-standard"
                              options={row?.groupList || []}
                              name="order_group_no"
                              value={row?.order_group_no}
                              isOptionEqualToValue={(option, value) =>
                                option === value
                              }
                              getOptionLabel={(option) => option}
                              onChange={(e, newValue) => {
                                if (newValue != null) {
                                  setFieldValue(
                                    `group_order_details[${i}].order_group_no`,
                                    newValue
                                  );
                                } else {
                                  setFieldValue(
                                    `group_order_details[${i}].order_group_no`,
                                    ""
                                  );
                                }
                              }}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                  {...props}
                                >
                                  {option}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  sx={{
                                    width: "100px",
                                  }}
                                />
                              )}
                            />
                            <Div sx={{ height: "15px" }}>
                              {errors?.group_order_details?.[i]
                                ?.order_group_no && (
                                <FormHelperText
                                  sx={{ ml: 2, color: "#FF0000" }}
                                >
                                  Required
                                </FormHelperText>
                              )}
                            </Div>
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            <Div sx={{ mb: 2 }}>
                              {row?.order_group_no != "" ? (
                                <Div
                                  onClick={() => {
                                    setOpen(true);
                                    setSelectedGroupNo(row?.order_group_no);
                                  }}
                                >
                                  <InfoIcon sx={{ cursor: "pointer" }} />
                                </Div>
                              ) : (
                                <Typography>Select Group No</Typography>
                              )}
                            </Div>
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            <Autocomplete
                              freeSolo
                              size="small"
                              id="tags-standard"
                              options={itemNameList || []}
                              name="order_item_name"
                              value={row?.order_item_name}
                              isOptionEqualToValue={(option, value) =>
                                option.item_name === value
                              }
                              getOptionLabel={(option) => {
                                if (option instanceof Object) {
                                  return option.item_name;
                                } else {
                                  return option;
                                }
                              }}
                              onChange={(e, newValue) => {
                                if (newValue != null) {
                                  setFieldValue(
                                    `group_order_details[${i}].order_item_name`,
                                    newValue?.item_name
                                  );
                                  getGroupNoBasedOnNameAndCode(
                                    values,
                                    i,
                                    setFieldValue,
                                    "order_item_name",
                                    newValue?.item_name
                                  );
                                } else {
                                  setFieldValue(
                                    `group_order_details[${i}].order_item_name`,
                                    ""
                                  );
                                  setFieldValue(
                                    `group_order_details[${i}].groupList`,
                                    []
                                  );
                                }
                              }}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                  {...props}
                                >
                                  {option?.item_name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  sx={{
                                    width: "100%",
                                  }}
                                />
                              )}
                            />
                            <Div sx={{ height: "15px" }}>
                              {errors?.group_order_details?.[i]
                                ?.order_item_name && (
                                <FormHelperText
                                  sx={{ ml: 2, color: "#FF0000" }}
                                >
                                  Required
                                </FormHelperText>
                              )}
                            </Div>
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            <Autocomplete
                              freeSolo
                              size="small"
                              id="tags-standard"
                              options={itemCodeList || []}
                              name="order_item_code"
                              value={row?.order_item_code}
                              isOptionEqualToValue={(option, value) =>
                                option.item_code === value
                              }
                              getOptionLabel={(option) => {
                                if (option instanceof Object) {
                                  return option.item_code;
                                } else {
                                  return option;
                                }
                              }}
                              onChange={(e, newValue) => {
                                if (newValue != null) {
                                  setFieldValue(
                                    `group_order_details[${i}].order_item_code`,
                                    newValue?.item_code
                                  );
                                  getGroupNoBasedOnNameAndCode(
                                    values,
                                    i,
                                    setFieldValue,
                                    "order_item_code",
                                    newValue?.item_code
                                  );
                                } else {
                                  setFieldValue(
                                    `group_order_details[${i}].order_item_code`,
                                    ""
                                  );
                                  setFieldValue(
                                    `group_order_details[${i}].groupList`,
                                    []
                                  );
                                }
                              }}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                  {...props}
                                >
                                  {option?.item_code}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  sx={{
                                    width: "100%",
                                  }}
                                />
                              )}
                            />
                            <Div sx={{ height: "15px" }}>
                              {errors?.group_order_details?.[i]
                                ?.order_item_code && (
                                <FormHelperText
                                  sx={{ ml: 2, color: "#FF0000" }}
                                >
                                  Required
                                </FormHelperText>
                              )}
                            </Div>
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            <TextField
                              type="number"
                              sx={{ width: "100%", ...disabledStyling }}
                              size="small"
                              inputProps={{
                                min: 0,
                              }}
                              onKeyDown={(e) => {
                                const inputValue = parseInt(
                                  e.target.value + e.key,
                                  10
                                );

                                if (isNaN(inputValue) || inputValue < 0) {
                                  e.preventDefault();
                                }
                              }}
                              value={row?.order_length}
                              name="order_length"
                              onChange={(e) =>
                                calculateSqm(e, setFieldValue, row, i)
                              }
                            />
                            <Div sx={{ height: "15px" }}>
                              {errors?.group_order_details?.[i]
                                ?.order_length && (
                                <FormHelperText
                                  sx={{ ml: 2, color: "#FF0000" }}
                                >
                                  Required
                                </FormHelperText>
                              )}
                            </Div>
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            <TextField
                              type="number"
                              sx={{ width: "100%", ...disabledStyling }}
                              size="small"
                              inputProps={{
                                min: 0,
                              }}
                              onKeyDown={(e) => {
                                const inputValue = parseInt(
                                  e.target.value + e.key,
                                  10
                                );

                                if (isNaN(inputValue) || inputValue < 0) {
                                  e.preventDefault();
                                }
                              }}
                              value={row?.order_width}
                              name="order_width"
                              onChange={(e) =>
                                calculateSqm(e, setFieldValue, row, i)
                              }
                            />
                            <Div sx={{ height: "15px" }}>
                              {errors?.group_order_details?.[i]
                                ?.order_width && (
                                <FormHelperText
                                  sx={{ ml: 2, color: "#FF0000" }}
                                >
                                  Required
                                </FormHelperText>
                              )}
                            </Div>
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            <TextField
                              type="number"
                              sx={{ width: "100%", ...disabledStyling }}
                              size="small"
                              inputProps={{
                                min: 0,
                              }}
                              onKeyDown={(e) => {
                                const inputValue = parseInt(
                                  e.target.value + e.key,
                                  10
                                );

                                if (isNaN(inputValue) || inputValue < 0) {
                                  e.preventDefault();
                                }
                              }}
                              value={row?.order_required_pcs}
                              name="order_required_pcs"
                              onChange={(e) => {
                                calculateSqm(e, setFieldValue, row, i);
                                setFieldValue(
                                  `group_order_details[${i}].order_balance_pcs_qty`,
                                  e.target.value
                                );
                              }}
                            />
                            <Div sx={{ height: "15px" }}>
                              {errors?.group_order_details?.[i]
                                ?.order_required_pcs && (
                                <FormHelperText
                                  sx={{ ml: 2, color: "#FF0000" }}
                                >
                                  Required
                                </FormHelperText>
                              )}
                            </Div>
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            <TextField
                              disabled
                              sx={{ width: "100%", ...disabledStyling }}
                              size="small"
                              value={row?.order_required_sqm}
                              name="order_required_sqm"
                              onChange={(e) =>
                                calculateSqm(e, setFieldValue, row, i)
                              }
                            />
                            <Div sx={{ height: "15px" }}>
                              {errors?.group_order_details?.[i]
                                ?.order_required_sqm && (
                                <FormHelperText
                                  sx={{ ml: 2, color: "#FF0000" }}
                                >
                                  Required
                                </FormHelperText>
                              )}
                            </Div>
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            <TextField
                              type="number"
                              sx={{ width: "100%", ...disabledStyling }}
                              size="small"
                              inputProps={{
                                min: 0,
                              }}
                              onKeyDown={(e) => {
                                const inputValue = parseInt(
                                  e.target.value + e.key,
                                  10
                                );

                                if (isNaN(inputValue) || inputValue < 0) {
                                  e.preventDefault();
                                }
                              }}
                              value={row?.order_rate}
                              name="order_rate"
                              onChange={(e) =>
                                calculateSqm(e, setFieldValue, row, i)
                              }
                            />
                            <Div sx={{ height: "15px" }}>
                              {errors?.group_order_details?.[i]?.order_rate && (
                                <FormHelperText
                                  sx={{ ml: 2, color: "#FF0000" }}
                                >
                                  Required
                                </FormHelperText>
                              )}
                            </Div>
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            <TextField
                              disabled
                              sx={{ width: "100%", ...disabledStyling }}
                              size="small"
                              value={row?.total_order_amount}
                              name="total_order_amount"
                              onChange={(e) =>
                                calculateSqm(e, setFieldValue, row, i)
                              }
                            />
                            <Div sx={{ height: "15px" }}>
                              {errors?.group_order_details?.[i]
                                ?.total_order_amount && (
                                <FormHelperText
                                  sx={{ ml: 2, color: "#FF0000" }}
                                >
                                  Required
                                </FormHelperText>
                              )}
                            </Div>
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            <TextField
                              sx={{
                                width: "100%",
                                mt: -1.8,
                                ...disabledStyling,
                              }}
                              size="small"
                              value={row?.item_remarks}
                              name="item_remarks"
                              onChange={(e) =>
                                calculateSqm(e, setFieldValue, row, i)
                              }
                            />
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "left",
                              px: 1,
                              position: "sticky",
                              right: 0,
                              zIndex: 1,
                              bgcolor: "white",
                            }}
                          >
                            <LoadingButton
                              loading={loader.removeProduct}
                              variant="outlined"
                              color="error"
                              disabled={values.group_order_details.length == 1}
                              onClick={() =>
                                removeRow(values, setFieldValue, i)
                              }
                              sx={{
                                width: "100%",
                                mb: 2,
                              }}
                            >
                              <RemoveCircleIcon color="error" />
                            </LoadingButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <LoadingButton
                    loading={loader.addProduct}
                    variant="outlined"
                    sx={{ marginX: 2, marginY: 1, width: "150px" }}
                    onClick={() => {
                      setLoader({ ...loader, addProduct: true });
                      setTimeout(() => {
                        const updateData = [...values["group_order_details"]];
                        updateData.push(initialValues.group_order_details[0]);
                        setFieldValue("group_order_details", updateData);
                        setLoader({ ...loader, addProduct: false });
                      }, 300);
                    }}
                  >
                    Add More
                  </LoadingButton>
                </TableContainer>
              </Div>
              <Div
                sx={{
                  width: "30%",
                  display: "flex",
                  alignItems: "center",
                  columnGap: 1,
                }}
              >
                <Div sx={{ width: "100%", mt: 2 }}>
                  <Typography variant="h5">Remark</Typography>
                  <TextField
                    sx={{ width: "100%", ...disabledStyling }}
                    size="small"
                    onChange={(e) =>
                      setFieldValue("order_remarks", e.target.value)
                    }
                    value={values.order_remarks}
                    name="order_remarks"
                  />
                </Div>
              </Div>
              {open && (
                <IssuedQuantityDetails
                  open={open}
                  setOpen={setOpen}
                  groupNo={selectedGroupNo}
                />
              )}

              <Div
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 3,
                  mt: 5,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure you want to cancel?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes",
                      cancelButtonText: "No",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigate("/dashboard/orders");
                      }
                    });
                  }}
                >
                  Cancel
                </Button>

                <LoadingButton
                  loading={isSubmitting}
                  variant="contained"
                  type="submit"
                >
                  Create Order
                </LoadingButton>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}

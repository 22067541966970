const AllApis = {
  dropdownList: {
    item_name: function (type) {
      return `/item-name-master/dropdown-item-name-master?type=${
        type ? type : ""
      }`;
    },
    item_code: "/item-code-master/dropdown-item-code-master",
    grade: "/grade-master/dropdown-grade-master",
    pallete: "/pallete-master/dropdown-pallete-master",
    unit: "/unit-master/dropdown-unit-master",
    roles: "/role/dropdown-roles-master",
    group_no: "/grouping/dropdown-group-no",
    customer_name: "/order/get-supplier-list-in-order",
    order_no: "/order/get-latest-order-no",
    order_no_list: "/order/get-order-no-list",
    palleteNoBasedOnItemNameAndCode: function (name, code) {
      return `/dispatch/get-item-pallete?item_name=${name}&item_code=${code}`;
    },
  },
  bulk: {
    raw: "/raw-material-inventory/bulk-upload-raw-material",
    itemName: "/item-name-master/bulk-upload-item-master",
    createdGroups: "",
  },

  //inventory
  raw_material: {
    addInventory: "/raw-material-inventory/add-raw-veneer",
    rawItemBundleNoExist: "/raw-material-inventory/raw-item_bundle_no-exist",
    editInventory: function (id) {
      return `/raw-material-inventory/update-raw-veneer-data?id=${id}`;
    },
    deleteInventory: function (id) {
      return `/raw-material-inventory/delete-raw-veneer?id=${id}`;
    },
    list: "/raw-material-inventory/fetch-raw-veneer",
    rejectQty: function (id) {
      return `/raw-material-inventory/reject-raw-veneer?id=${id}`;
    },
    addGrouping: "/raw-material-inventory/issue-for-grouping",
    listSqm: "/raw-material-inventory/available-sqm",
    history: "/raw-material-inventory/fetch-raw-veneer-history",
    addSmoking: "/raw-material-inventory/issue-for-smoking",
    addDying: "/raw-material-inventory/issue-for-dying",
    addSingleSmoking: "/raw-material-inventory/issue-for-smoking-pattas",
    addSingleDying: "/raw-material-inventory/issue-for-dying-pattas",
    list_issued_smoking: "/raw-material-inventory/issued-for-smoking-raw-list",
    list_issued_dying: "/raw-material-inventory/issued-for-dying-raw-list",
    cancelSmoking: "/raw-material-inventory/cancel-smoking-raw",
    cancelDying: "/raw-material-inventory/cancel-dying-raw",
    editRawMaterial: function (id) {
      return `/raw-material-inventory/update-raw-veneer?id=${id}`;
    },
  },

  other_goods: {
    addInventory: "/other-goods-inventory/add-other-goods",
    editInventory: function (id) {
      return `/other-goods-inventory/edit-other-goods?itemId=${id}`;
    },
    list: "/other-goods-inventory/list-other-goods",
    consumption: function (id) {
      return `/other-goods-inventory/update-other-goods?id=${id}`;
    },
    consumptionList: "/other-goods-inventory/list-other-goods-consumed",
  },

  ready_sheet_form: {
    list: "/ready-sheet-form/list",
    historyList: "/ready-sheet-form/history-list",
    approved: "/ready-sheet-form/approve",
    reject: "/ready-sheet-form/reject",
    split: "/ready-sheet-form/split",
    editRawReadySheetForm: function (id) {
      return `ready-sheet-form/update-ready-sheet-form?id=${id}`;
    },
    revertReadySheetForm: function (id) {
      return `/ready-sheet-form/revert?issuedId=${id}`;
    },
  },

  grouping: {
    list: "/grouping/list-issued-for-grouping",
    issueForSmokingGroup: "/grouping/issue-for-smoking-group",
    issueForDyingGroup: "/grouping/issue-for-dying-group",
    groupHistory: "/grouping/list-group-history",
    cancelGrouping: "/raw-material-inventory/cancel-grouping",
    rejectGroupBundle: "/raw-material-inventory/reject-raw-veneer-multiple",
    latestGroupNo: "/grouping/get-group-no",
    addGrouping: "/grouping/create-group",
    createdGroupList: "/grouping/list-group",
    issueForCutting: "/grouping/issue-for-cutting",
    cancelSmoking: "/grouping/cancel-smoking-group",
    cancelDying: "/grouping/cancel-dying-group",
    editGroup: function (id) {
      return `/grouping/update-group-veneer?id=${id}`;
    },
    revertGroup: function (id) {
      return `/grouping/revert-group?groupId=${id}`;
    },
  },

  //factory
  cutting: {
    issueForCutting: "/cutting/list-issued-for-cutting",
    cuttingDone: "/cutting/cutting-done-list",
    createCutting: "/cutting/create-cutting",
    revertIssueCutting: function (id) {
      return `cutting/revert-issued-for-cutting?issuedId=${id}`;
    },
  },

  smoking: {
    issuedForSmokingGroups: "/grouping/list-issued-for-smoking-group",
    createSmokingIndividual: "/smoking/create-individual-smoked",
    createSmokingGrouping: "/smoking/create-group-smoked",
    smokedIndividuals: "/smoking/list-individual-smoked",
    smokedGroups: "/smoking/list-group-smoked",
    passGroups: "/smoking/pass-group-smoked",
    rejectGroups: "/smoking/reject-group-smoked",
    passIndividuals: "/smoking/pass-individual-smoked",
    rejectIndividuals: "/smoking/reject-individual-smoked",
  },
  dying: {
    issuedForDyingGroups: "/grouping/list-issued-for-dying-group",
    dyedIndividuals: "/dying/list-individual-dyed",
    dyedGroups: "/dying/list-group-dyed",
    createDyingIndividual: "/dying/create-individual-dyed",
    createDyingGrouping: "/dying/create-group-dyed",
    passGroups: "/dying/pass-group-dyed",
    rejectGroups: "/dying/reject-group-dyed",
    passIndividuals: "/dying/pass-individual-dyed",
    rejectIndividuals: "/dying/reject-individual-dyed",
  },
  tapping: {
    issuedForTapping: "/tapping/issued-for-tapping-list",
    TappingDone: "/tapping/tapping-done-list",
    createTapping: "/tapping/create-tapping",
    revertIssueTapping: function (id) {
      return `/tapping/revert-issued-for-tapping?issuedId=${id}`;
    },
  },
  pressing: {
    issuedForPressing: "/pressing/issued-for-pressing-list",
    pressingDone: "/pressing/pressing-done-list",
    createPressing: "/pressing/create-pressing",
    revertIssuePressing: function (id) {
      return `/pressing/revert-issued-for-pressing?issuedId=${id}`;
    },
  },
  finishing: {
    issuedForFinishing: "/finishing/issued-for-finishing-list",
    finishingDone: "/finishing/finishing-done-list",
    createFinishing: "/finishing/create-finishing",
    updateStatus: "/finishing/update-finishing-status",
    revertIssueFinishing: function (id) {
      return `/finishing/revert-issued-for-finishing?issuedId=${id}`;
    },
  },
  dispatch: {
    readyForDispatchInventory: "/ready-for-dispatch-inventory/list",
    editReadyForDispatch: function (id) {
      return `/ready-for-dispatch-inventory/update?id=${id}`;
    },
    rawDispatched: "/dispatch/list-raw-dispatched",
    groupDispatched: "/dispatch/list-group-dispatched",
    getAvlGroupData: function (groupNo) {
      return `/dispatch/get-available-group-data?group_no=${groupNo}`;
    },
    getAvlRawData: "/dispatch/get-available-raw-data",
    createDispatch: "/dispatch/create-dispatch",
    deleteRawDispatch: function (id) {
      return `/dispatch/delete-raw-dispatch?dispatchId=${id}`;
    },
    deleteGroupDispatch: function (id) {
      return `/dispatch/delete-group-dispatch?dispatchId=${id}`;
    },
    revertReadyForDispatch: function (id) {
      return `/ready-for-dispatch-inventory/revert?id=${id}`;
    },
  },
  order: {
    groupNoBasedOnItemAndCode: function (name, code) {
      return `/order/get-group-no-based-on-item-name-and-item-code?item_name=${name}&item_code=${code}`;
    },
    issuedDetailsBasedOnGroupNo: function (groupNo) {
      return `/order/get-view-details?group_no=${groupNo}`;
    },
    create: "/order/add-order",
    edit: function (id) {
      return `/order/update-order?id=${id}`;
    },
    pendingRawOrders: "/order/list-pending-raw-order",
    pendingGroupOrders: "/order/list-pending-group-order",
    completeRawOrders: "/order/list-complete-raw-order",
    completeGroupOrders: "/order/list-complete-group-order",
  },

  images: {
    listImageBasedOnGroupNo: function (groupNo) {
      return `/image/get-image-based-on-group?group_no=${groupNo}`;
    },
    listImageBasedOnItemId: function (id) {
      return `/image/get-image-based-on-itemId?id=${id}`;
    },
    updateImage: function (id) {
      return `/image/update-image?id=${id}`;
    },
  },

  reports: {
    //raw
    rawVeneer: "/report/download-excel-raw-veneer",
    issueForSmoking: "/report/download-excel-raw-issue-for-smoking",
    issueForDying: "/report/download-excel-raw-issue-for-dying",
    issueForGrouping: "/report/download-excel-raw-issue-for-grouping",
    //grouping
    createdGroups: "/report/download-excel-created-group-veneer",
    issuedForSmokingGroups: "/report/download-excel-issued-for-smoking-groups",
    issuedForDyingGroups: "/report/download-excel-issued-for-dying-groups",
    groupingHistory: "/report/download-excel-group-history",
    groupingHistoryNormal: "/report/download-excel-group-history-normal",
    //smoking
    smokedIndividual: "/report/download-excel-smoked-individual",
    smokedGrouped: "/report/download-excel-smoked-group",
    //dying
    dyedIndividual: "/report/download-excel-dyed-individual",
    dyedGrouped: "/report/download-excel-dyed-group",
    //cutting
    issuedForCutting: "/report/download-excel-issue-for-cutting",
    cuttingDone: "/report/download-excel-cutting-done",
    //tapping
    issuedForTapping: "/report/download-excel-issue-for-tapping",
    tappingDone: "/report/download-excel-created-tapping",
    //pressing
    issuedForPressing: "/report/download-excel-issue-for-pressing",
    pressingDone: "/report/download-excel-pressing-done",
    //finishing
    issuedForFinishing: "/report/download-excel-issue-for-finishing",
    finishingDone: "/report/download-excel-finishing-done",
    //orders & dispatch
    pendingRawOrders: "/report/download-excel-pending-raw-order",
    pendingGroupOrders: "/report/download-excel-pending-group-order",
    completeRawOrders: "/report/download-excel-complete-raw-order",
    completeGroupOrders: "/report/download-excel-complete-group-order",
    dispatchGroupDone: "/report/download-excel-dispatched-group-done",
    dispatchRawDone: "/report/download-excel-dispatched-raw-order",
    //readySheetForm
    readySheetForm: "/report/download-excel-ready-sheet-form",

    //ReadyForDispatch
    readyForDispatch: "/report/download-excel-ready-for-dispatch",

    //consumeGoods
    consumeGoods: function (month, year) {
      return `/report/download-excel-consumed-goods?month=${month}&year=${year}`;
    },
    //otherGoods
    otherGoods: `/report/download-excel-other-goods`,

    //dashboard
    dashboard: function (from, to) {
      return `/report/download-excel-dashboard?from=${from}&to=${to}`;
    },
  },
};
export default AllApis;

/* eslint-disable default-case */
import Div from "@jumbo/shared/Div";
import InfoIcon from "@mui/icons-material/Info";
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { formatDate } from "app/components/Function/formatDate";
import { getTappingDoneList } from "app/redux/actions/factoryAction";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CuttingDtailsModal } from "../Modal/cuttingDetails";
import FullScreenLoader from "app/components/ListingPageLoader";
import ViewImage from "../Modal/viewImage";

export default function TappingDone({
  page,
  setPage,
  searchTerm,
  filter,
  sort,
  setSort,
  sortBy,
  setSortBy,
}) {
  const dispatch = useDispatch();
  const { tappingDone, error, TotalPage, loading } = useSelector(
    (state) => state.factoryReducer
  );

  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState({});
  const [openView, setOpenView] = useState(false);

  const handleSort = (property) => {
    setSort(sort == "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getTappingDoneList(searchTerm, sort, sortBy, newPage, filter));
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow sx={{
              bgcolor: "#7352C7", color: "white", "& .MuiTableCell-root": {
                py: 2,
              },
            }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_history_id.group_id.group_no"}
                  direction={sort}
                  onClick={() =>
                    handleSort("group_history_id.group_id.group_no")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Group No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "300px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={
                    sortBy ===
                    "cutting_id.cutting_id.item_details.item_data.item_name"
                  }
                  direction={sort}
                  onClick={() =>
                    handleSort(
                      "cutting_id.cutting_id.item_details.item_data.item_name"
                    )
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "110px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={
                    sortBy ===
                    "cutting_id.cutting_id.item_details.item_data.item_code"
                  }
                  direction={sort}
                  onClick={() =>
                    handleSort(
                      "cutting_id.cutting_id.item_details.item_data.item_code"
                    )
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Type
                </TableSortLabel>
              </TableCell>

              <TableCell sx={{ color: "white", minWidth: "152px", px: 1 }}>
                Tapping Length (cm)
              </TableCell>
              <TableCell sx={{ color: "white", minWidth: "150px", px: 1 }}>
                Tapping Width (cm)
              </TableCell>
              <TableCell sx={{ color: "white", minWidth: "150px", px: 1 }}>
                Tapping No of pcs
              </TableCell>
              <TableCell sx={{ color: "white", minWidth: "110px", px: 1 }}>Tapping Sq.m.</TableCell>
              <TableCell sx={{ color: "white", minWidth: "100px", px: 1 }}>Waste Sq.m.</TableCell>
              <TableCell sx={{ color: "white", minWidth: "140px", px: 1 }}>
                Waste Percentage
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "center",
                  minWidth: "115px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Cutting Details
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  minWidth: "110px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                View Images
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "created_employee_id.first_name"}
                  direction={sort}
                  onClick={() => handleSort("created_employee_id.first_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Created By
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Tapping Done Date
              </TableCell>

              <TableCell sx={{ color: "white", px: 1, minWidth: "180px" }}>
                Tapping Remarks
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tappingDone?.map((tapping, i) => (
              <TableRow key={i}>
                <TableCell sx={{ textAlign: "left" }}>
                  {tapping?.group_data?.group_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {
                    tapping?.cutting_id?.[0]?.cutting_id?.[0]?.item_details
                      ?.item_data?.[0]?.item_name
                  }
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {
                    tapping?.cutting_id?.[0]?.cutting_id?.[0]?.item_details
                      ?.item_data?.[0]?.item_code
                  }
                </TableCell>

                <TableCell sx={{ px: 1 }}>{tapping?.tapping_length}</TableCell>
                <TableCell sx={{ px: 1 }}>{tapping?.tapping_width}</TableCell>
                <TableCell sx={{ px: 1 }}>
                  {tapping?.tapping_no_of_pcs}
                </TableCell>
                <TableCell sx={{ px: 1 }}>{tapping?.tapping_sqm}</TableCell>
                <TableCell sx={{ px: 1 }}>
                  {tapping?.tapping_waste_sqm}
                </TableCell>
                <TableCell sx={{ px: 1 }}>
                  {tapping?.tapping_waste_sqm_percentage
                    ? `${tapping?.tapping_waste_sqm_percentage} %`
                    : "-"}
                </TableCell>

                <TableCell sx={{ textAlign: "center", px: 2 }}>
                  <Div
                    onClick={() => {
                      setOpen(true);
                      setRowData(tapping?.cutting_id[0]?.cutting_id);
                    }}
                  >
                    <InfoIcon sx={{ cursor: "pointer" }} />
                  </Div>
                </TableCell>
                <TableCell sx={{ textAlign: "center", px: 2 }}>
                  <Div
                    onClick={() => {
                      setOpenView(true);
                      setRowData(tapping);
                    }}
                  >
                    <InfoIcon sx={{ cursor: "pointer" }} />
                  </Div>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    px: 1,
                    textTransform: "capitalize",
                  }}
                >
                  {tapping?.created_employee_id?.first_name}{" "}
                  {tapping?.created_employee_id?.last_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {formatDate(tapping?.taping_done_date)}
                </TableCell>
                <TableCell sx={{ px: 1 }}>
                  {tapping?.tapping_remarks ? tapping?.tapping_remarks : "-"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {open && (
            <CuttingDtailsModal
              open={open}
              setOpen={setOpen}
              rowData={rowData}
            />
          )}
          {openView && (
            <ViewImage
              openView={openView}
              setOpenView={setOpenView}
              data={rowData}
            />
          )}
        </Table>
        <Pagination size="medium"
          count={TotalPage || 1}
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
            py: 1
          }}
        />
      </TableContainer>
    </>
  );
}

import Page from "@jumbo/shared/Page";
import InventoryList from "app/pages/Inventory/List Inventory";
import AddStock from "app/pages/Inventory/AddStock";
import InventoryRouteMiddleware from "./middleware/auth/inventoryValidRoute";
import EditStock from "app/pages/Inventory/EditStock";

export const rawMaterialRoute = [
  {
    middleware: [
      {
        element: InventoryRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/inventory/rawmaterial",
        element: <Page component={InventoryList} layout={"vertical-default"} />,
      },
      {
        path: "/dashboard/inventory/add",
        element: <Page component={AddStock} layout={"vertical-default"} />,
      },
      {
        path: "/dashboard/inventory/edit",
        element: <Page component={EditStock} layout={"vertical-default"} />,
      },
    ],
  },
];

import Div from "@jumbo/shared/Div";
import {
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";

export const CuttingDetailsModal = ({ open, setOpen, rowData }) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={1000}>
      <DialogContent>
        <Typography variant="h3" color="initial" fontWeight={600}>
          Cutting Bundles Details:-
        </Typography>
        <Div>
          <Table size="small">
            <TableHead>
              <TableRow sx={{
              bgcolor: "#7352C7", color: "white", "& .MuiTableCell-root": {
                py: 2,
              },
            }}>
                <TableCell sx={{ color: "white" }}>Item Name</TableCell>
                <TableCell sx={{ color: "white" }}>Item Type</TableCell>
                <TableCell sx={{ color: "white" }}>Rate Per Sq.m.</TableCell>
                <TableCell sx={{ color: "white" }}>Log No.</TableCell>
                <TableCell sx={{ color: "white" }}>Bundle No.</TableCell>

                <TableCell sx={{ color: "white" }}>Cutting Length (cm)</TableCell>
                <TableCell sx={{ color: "white" }}>Cutting Width (cm)</TableCell>
                <TableCell sx={{ color: "white" }}>No. Of Pattas</TableCell>
                <TableCell sx={{ color: "white" }}>Sq.m.</TableCell>

                <TableCell sx={{ color: "white" }}>Available Pattas</TableCell>
                {/* <TableCell sx={{ color: "white" }}>Natural</TableCell>
                <TableCell sx={{ color: "white" }}>Dyed</TableCell>
                <TableCell sx={{ color: "white" }}>Smoked</TableCell>
                <TableCell sx={{ color: "white" }}>Total</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData.map((ele) => {
                return (
                  <TableRow sx={{ bgcolor: "#EDEBEB" }}>
                    <TableCell>
                      {ele?.item_details?.item_data?.[0]?.item_name}
                    </TableCell>
                    <TableCell>
                      {ele?.item_details?.item_data?.[0]?.item_code}
                    </TableCell>
                    <TableCell>
                      {ele?.item_details?.item_data?.[0]?.item_rate_per_sqm}
                    </TableCell>
                    <TableCell>
                      {ele?.item_details?.item_data?.[0]?.item_log_no}
                    </TableCell>
                    <TableCell>
                      {ele?.item_details?.item_data?.[0]?.item_bundle_no}
                    </TableCell>

                    <TableCell>{ele?.item_details?.cutting_length}</TableCell>
                    <TableCell>{ele?.item_details?.cutting_width}</TableCell>
                    <TableCell>
                      {ele?.item_details?.cutting_no_of_pattas}
                    </TableCell>
                    <TableCell>{ele?.item_details?.cutting_sqm}</TableCell>

                    <TableCell>
                      {ele?.item_details?.final_cutting_quantity}
                    </TableCell>
                    {/* <TableCell>
                      {ele?.item_details?.final_cutting_quantity?.dyed}
                    </TableCell>
                    <TableCell>
                      {ele?.item_details?.final_cutting_quantity?.smoked}
                    </TableCell>
                    <TableCell>
                      {ele?.item_details?.final_cutting_quantity?.total}
                    </TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Div>
      </DialogContent>
    </Dialog>
  );
};

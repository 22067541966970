/* eslint-disable default-case */
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import Div from "@jumbo/shared/Div";
import CancelIcon from "@mui/icons-material/Cancel";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import InfoIcon from "@mui/icons-material/Info";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Checkbox,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import AllApis from "app/Apis";
import FullScreenLoader from "app/components/ListingPageLoader";
import { getAllCreatedGroups } from "app/redux/actions/groupingAction";
import { displayDateFun } from "app/utils/constants/functions";
import { Axios } from "index";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { GroupBundlesModal } from "../Modal/groupBundlesDetails";
import ViewImage from "../Modal/viewImage";

export default function CreatedGroups({
  page,
  setPage,
  searchTerm,
  filter,
  sort,
  setSort,
  sortBy,
  setSortBy,
  addGroup,
  setAddGroup,
  palleteList,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { createdGroups, error, TotalPage, loading } = useSelector(
    (state) => state.groupingReducer
  );
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openView, setOpenView] = useState(false);

  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );

  const disabledStyling = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#475259",
    },
    "& .MuiInputLabel-root": {
      color: "#475259", // Change label color to blue
    },
  };

  const handleSort = (property) => {
    setSort(sort == "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getAllCreatedGroups(searchTerm, sort, sortBy, newPage, filter));
  };

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "reject":
        navigate(`/dashboard/grouping/reject`, {
          state: menuItem.data,
        });
        break;
      case "cutting":
        navigate(`/dashboard/grouping/issuecutting`, {
          state: menuItem.data,
        });
        break;
      case "revert":
        revertGroup(menuItem?.data?._id);
        break;
    }
  };

  const revertGroup = async (id) => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Revert Group",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        try {
          const response = await Axios.post(
            AllApis.grouping.revertGroup(id),
            {},
            config
          );
          if (response) {
            dispatch(getAllCreatedGroups(searchTerm, sort, sortBy, page, filter));
            Swal.fire({
              title: "Group Reverted",
              icon: "success",
              timer: 1000,
              showConfirmButton: false,
            });
          }
        } catch (error) {
          Swal.fire({
            title: error?.response?.data?.error,
            icon: "error",
            timer: 1000,
            showConfirmButton: false,
          });
        } finally {
          setIsLoading(false);
        }
      }
    });
  };

  const editGroup = async (id, name, value) => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    if (value != null) {
      try {
        const response = await Axios.patch(
          AllApis.grouping.editGroup(id),
          {
            [name]: name == "group_pallete_no" ? value.pallete_no : value,
            group_physical_location: value.item_physical_location,
          },
          config
        );
        if (response?.data?.status) {
          Swal.fire({
            title: "Updated",
            icon: "success",
            timer: 1000,
            showConfirmButton: false,
          });
          dispatch(getAllCreatedGroups(searchTerm, sort, sortBy, page, filter));
        }
      } catch (error) {
        Swal.fire({
          title: error?.response?.data.message,
          icon: "error",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    }
  };

  const addToSmokingGroup = async (type) => {
    let module;
    let msg;
    if (type == "smoke") {
      module = "issueForSmokingGroup";
      msg = "Issued For Smoking";
    } else if (type == "dying") {
      module = "issueForDyingGroup";
      msg = "Issued For Dying";
    }
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    try {
      setIsLoading(true);
      const response = await Axios.post(
        AllApis.grouping[module],
        { item_details: addGroup },
        config
      );
      if (response?.data?.status) {
        Swal.fire({
          title: `Added To ${msg}`,
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        dispatch(getAllCreatedGroups(searchTerm, sort, sortBy, page, filter));
        setAddGroup([]);
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckbox = (e, group_id) => {
    if (e.target.checked == true) {
      const selectedArr = [...addGroup];
      selectedArr.push(group_id);
      setAddGroup(selectedArr);
    } else {
      const selectedArr = [...addGroup];
      const deletedIds = selectedArr.filter((ele) => ele != group_id);
      setAddGroup(deletedIds);
    }
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <>
      {isLoading && <FullScreenLoader />}
      {addGroup?.length > 0 && (
        <Div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
            bgcolor: "#dbdbdb",
            p: 2,
            borderRadius: "5px",
          }}
        >
          <Typography variant="h5" sx={{ color: "black", mt: 1 }}>
            {addGroup?.length} Groups Selected
          </Typography>
          <Div sx={{ display: "flex", columnGap: 3 }}>
            <LoadingButton
              variant="contained"
              onClick={() => addToSmokingGroup("smoke")}
            >
              Issue For Smoking Group
            </LoadingButton>
            <LoadingButton
              variant="contained"
              onClick={() => addToSmokingGroup("dying")}
            >
              Issue For Dying Group
            </LoadingButton>
            <LoadingButton
              variant="contained"
              color="error"
              onClick={() => setAddGroup([])}
            >
              Cancel
            </LoadingButton>
          </Div>
        </Div>
      )}

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow sx={{
              bgcolor: "#7352C7", color: "white", "& .MuiTableCell-root": {
                // py: 2,
              },
            }}>
              {permissions?.grouping_create && (
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "95px",
                    color: "white",
                  }}
                >
                  Select
                </TableCell>
              )}
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "110px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_no"}
                  direction={sort}
                  onClick={() => handleSort("group_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Group No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "300px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_details.item_name"}
                  direction={sort}
                  onClick={() => handleSort("item_details.item_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_details.item_code"}
                  direction={sort}
                  onClick={() => handleSort("item_details.item_code")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Type
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "165px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_length"}
                  direction={sort}
                  onClick={() => handleSort("group_length")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Group Length (cm)
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "160px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_width"}
                  direction={sort}
                  onClick={() => handleSort("group_width")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Group Width (cm)
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "140px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_pcs"}
                  direction={sort}
                  onClick={() => handleSort("group_pcs")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Est No. Of Pcs
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_no_of_pattas_available"}
                  direction={sort}
                  onClick={() => handleSort("group_no_of_pattas_available")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  No of Pattas
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_sqm_available"}
                  direction={sort}
                  onClick={() => handleSort("group_sqm_available")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Group Sq.m.
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "180px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "total_item_sqm_available"}
                  direction={sort}
                  onClick={() => handleSort("total_item_sqm_available")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Total Item Sq.m. Avl
                </TableSortLabel>
              </TableCell>

              {/* <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "waste_sqm"}
                  direction={sort}
                  onClick={() => handleSort("waste_sqm")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Waste Sq.m.
                </TableSortLabel>
              </TableCell> */}

              {/* <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "waste_sqm_percentage"}
                  direction={sort}
                  onClick={() => handleSort("waste_sqm_percentage")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Waste %
                </TableSortLabel>
              </TableCell> */}

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "70px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_grade"}
                  direction={sort}
                  onClick={() => handleSort("group_grade")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Grade
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "105px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "orientation"}
                  direction={sort}
                  onClick={() => handleSort("orientation")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Orientation
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "book_type"}
                  direction={sort}
                  onClick={() => handleSort("book_type")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Formation Type
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "200px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_pallete_no"}
                  direction={sort}
                  onClick={() => handleSort("group_pallete_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Group Pallet No
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "200px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_physical_location"}
                  direction={sort}
                  onClick={() => handleSort("group_physical_location")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Physical Location
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "180px",
                  verticalAlign: "middle",
                  color: "white",
                  px: 1,
                }}
              >
                Remarks
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "155px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Date of Grouping
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Bundle Details
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Group Images
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "created_employee_id.first_name"}
                  direction={sort}
                  onClick={() => handleSort("created_employee_id.first_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Created By
                </TableSortLabel>
              </TableCell>
              {permissions?.grouping_edit == true && (
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "95px",
                    verticalAlign: "middle",
                    color: "white",
                    px: 1,
                    position: "sticky",
                    right: 0,
                    height: "58px",
                    zIndex: 1,
                    bgcolor: "#7352C7",
                  }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {createdGroups?.map((row, i) => (
              <TableRow key={i}>
                {permissions?.grouping_create && (
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    <Checkbox
                      onClick={(event) => handleCheckbox(event, row?._id)}
                      color="primary"
                      checked={addGroup?.some((ele) => ele == row._id)}
                    />
                  </TableCell>
                )}
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.item_details?.[0]?.item_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.item_details?.[0]?.item_code}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_length}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_width}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_pcs}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_no_of_pattas_available}
                </TableCell>

                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_sqm_available?.toFixed(2)}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.total_item_sqm_available?.toFixed(2)}
                </TableCell>
                {/* <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.waste_sqm}
                </TableCell> */}
                {/* <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.waste_sqm_percentage > 0
                    ? `${row?.waste_sqm_percentage} %`
                    : "-"}
                </TableCell> */}
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_grade}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {row?.orientation}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {row?.book_type}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  <Autocomplete
                    size="small"
                    id="tags-standard"
                    options={palleteList || []}
                    name="group_pallete_no"
                    value={row?.group_pallete_no}
                    isOptionEqualToValue={(option, value) =>
                      option.pallete_no === value
                    }
                    getOptionLabel={(option) => {
                      // console.log(option);
                      if (option instanceof Object) {
                        return option.pallete_no;
                      } else {
                        return option;
                      }
                    }}
                    onChange={(e, newValue) => {
                      editGroup(row._id, "group_pallete_no", newValue);
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option?.pallete_no}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {/* <Select
                    sx={{ width: "100%" }}
                    size="small"
                    name="physical_location"
                    value={row?.group_physical_location}
                    onChange={(e) => {
                      editGroup(
                        row._id,
                        "group_physical_location",
                        e.target.value
                      );
                    }}
                  >
                    <MenuItem value={"TERRACE"}>TERRACE</MenuItem>
                    <MenuItem value={"ROLL GODOWN"}>ROLL GODOWN</MenuItem>
                    <MenuItem value={"TEXTURE GODOWN"}>TEXTURE GODOWN</MenuItem>
                    <MenuItem value={"VEENEER SECTION"}>
                      VEENEER SECTION
                    </MenuItem>
                    <MenuItem value={"GODWON-1"}>GODWON-1</MenuItem>
                  </Select> */}
                  <TextField
                    multiline
                    size="small"
                    value={row?.group_physical_location}
                    sx={{ width: "100%", ...disabledStyling }}
                    name="group_physical_location"
                    disabled
                  />
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_remarks ? row?.group_remarks : "-"}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {displayDateFun(row?.date_of_grouping)}
                </TableCell>
                <TableCell sx={{ textAlign: "center", px: 2 }}>
                  <Div
                    onClick={() => {
                      setOpen(true);
                      setRowData(row);
                    }}
                  >
                    <InfoIcon sx={{ cursor: "pointer" }} />
                  </Div>
                </TableCell>
                <TableCell sx={{ textAlign: "center", px: 2 }}>
                  <Div
                    onClick={() => {
                      setOpenView(true);
                      setRowData(row);
                    }}
                  >
                    <InfoIcon sx={{ cursor: "pointer" }} />
                  </Div>
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {row?.created_employee_id?.first_name}{" "}
                  {row?.created_employee_id?.last_name}
                </TableCell>

                {permissions?.grouping_edit == true && (
                  <TableCell
                    sx={{
                      textAlign: "left",
                      px: 1,
                      position: "sticky",
                      right: 0,
                      zIndex: 1,
                      bgcolor: "white",
                    }}
                  >
                    <JumboDdMenu
                      icon={<MoreHorizIcon />}
                      menuItems={[
                        {
                          icon: <CancelIcon />,
                          title: "Reject Grouping",
                          action: "reject",
                          data: row,
                        },
                        {
                          icon: <ControlPointIcon />,
                          title: "Issue For Cutting",
                          action: "cutting",
                          data: row,
                        },

                        {
                          icon: <SettingsBackupRestoreIcon />,
                          title: "Revert Group",
                          action: "revert",
                          data: row,
                        },
                      ]}
                      onClickCallback={handleItemAction}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
          {open && (
            <GroupBundlesModal
              open={open}
              setOpen={setOpen}
              rowData={rowData}
            />
          )}
          {openView && (
            <ViewImage
              openView={openView}
              setOpenView={setOpenView}
              data={rowData}
            />
          )}
        </Table>
        <Pagination size="medium"
          count={TotalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
            py:1
          }}
        />
      </TableContainer>
    </>
  );
}

/* eslint-disable default-case */
import { Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import ViewImage from "../Modal/viewImage";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import Div from "@jumbo/shared/Div";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AllApis from "app/Apis";
import { formatDate } from "app/components/Function/formatDate";
import { formatDateWithTime } from "app/components/Function/formatDateWithTime";
import { RawInventoryModal } from "app/components/Modal/rawInventoryModal";
import { IssuedDyingPattasModal } from "app/pages/Inventory/TableComponent/Modals/issuedDyingPattas";
import { getAllDyedIndividuals } from "app/redux/actions/factoryAction";
import { Axios } from "index";
import { useNavigate } from "react-router-dom";
import FullScreenLoader from "app/components/ListingPageLoader";

export default function DyedIndividualsTable({ page, setPage, searchTerm, filter, sort, setSort, sortBy, setSortBy }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dyedIndividuals, error, TotalPage, loading } = useSelector((state) => state.factoryReducer);
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState({});
  const [issuedSmokingDetails, setIssuedSmokingDetails] = useState(false);
  const [openView, setOpenView] = useState(false);

  const permissions = useSelector((state) => state?.userReducer?.user?.[0]?.role_id?.permissions);

  const handleSort = (property) => {
    setSort(sort == "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getAllDyedIndividuals(searchTerm, sort, sortBy, newPage, filter));
  };

  const handleAction = async (type, id) => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    try {
      const response = await Axios.post(AllApis.dying[type == "pass" ? "passIndividuals" : "rejectIndividuals"], { id: id }, config);
      if (response?.data?.message) {
        Swal.fire({
          title: `${type == "pass" ? "Passed" : "Rejected"}`,
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        dispatch(getAllDyedIndividuals(searchTerm, sort, sortBy, page, filter));
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
    }
  };
  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "pass":
        Swal.fire({
          title: "Are you sure you want to Pass?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            handleAction("pass", menuItem.data._id);
          }
        });

        break;
      case "reject":
        Swal.fire({
          title: "Are you sure you want to Reject?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            handleAction("reject", menuItem.data._id);
          }
        });
        break;
    }
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                bgcolor: "#7352C7",
                color: "white",
                "& .MuiTableCell-root": {
                  // py: 2,
                },
              }}
            >
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "300px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_details.item_name"}
                  direction={sort}
                  onClick={() => handleSort("item_details.item_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_details.item_code"}
                  direction={sort}
                  onClick={() => handleSort("item_details.item_code")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Type
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "90px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_details.item_log_no"}
                  direction={sort}
                  onClick={() => handleSort("item_details.item_log_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Log No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "110px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_details.item_bundle_no"}
                  direction={sort}
                  onClick={() => handleSort("item_details.item_bundle_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Bundle No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_details.item_length"}
                  direction={sort}
                  onClick={() => handleSort("item_details.item_length")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Length (cm)
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "115px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_details.item_width"}
                  direction={sort}
                  onClick={() => handleSort("item_details.item_width")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Width (cm)
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "115px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_details.item_available_pattas"}
                  direction={sort}
                  onClick={() => handleSort("item_details.item_available_pattas")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Avl Pattas
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "125px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "issued_dying_quantity.total"}
                  direction={sort}
                  onClick={() => handleSort("issued_dying_quantity.total")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Dyed Pattas
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_details.item_available_sqm"}
                  direction={sort}
                  onClick={() => handleSort("item_details.item_available_sqm")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Avl Total Sq.m.
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "115px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                In Time
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "115px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Out Time
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Processed Time (Hrs)
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "consumed_item_name"}
                  direction={sort}
                  onClick={() => handleSort("consumed_item_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Consumed Item
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "liters_of_ammonia_used"}
                  direction={sort}
                  onClick={() => handleSort("liters_of_ammonia_used")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Consumed Qty (Ltr)
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "75px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_details.item_grade"}
                  direction={sort}
                  onClick={() => handleSort("item_details.item_grade")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Grade
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "110px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_details.item_pallete_no"}
                  direction={sort}
                  onClick={() => handleSort("item_details.item_pallete_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Pallet No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "155px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_details.item_physical_location"}
                  direction={sort}
                  onClick={() => handleSort("item_details.item_physical_location")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Physical Location
                </TableSortLabel>
              </TableCell>
              {/* <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "85px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Dying Details
              </TableCell> */}
              <TableCell
                sx={{
                  textAlign: "center",
                  minWidth: "65px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Details
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  minWidth: "110px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                View Images
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "155px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_details.supplier_details.supplier_name"}
                  direction={sort}
                  onClick={() => handleSort("item_details.supplier_details.supplier_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Supplier
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "180px",
                  verticalAlign: "middle",
                  color: "white",
                  px: 1,
                }}
              >
                Remark
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Dyed Date
              </TableCell>
              {permissions?.dying_edit == true && (
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "95px",
                    verticalAlign: "middle",
                    color: "white",
                    px: 1,
                    position: "sticky",
                    right: 0,
                    height: "58px",
                    zIndex: 1,
                    bgcolor: "#7352C7",
                  }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {dyedIndividuals?.map((row, i) => (
              <TableRow key={i}>
                <TableCell sx={{ textAlign: "left" }}>{row?.item_details?.item_name}</TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>{row?.item_details?.item_code}</TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>{row?.item_details?.item_log_no}</TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>{row?.item_details?.item_bundle_no}</TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>{row?.item_details?.item_length}</TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>{row?.item_details?.item_width}</TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>{row?.item_details?.item_available_pattas}</TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>{row?.issued_dying_quantity}</TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>{row?.item_details?.item_available_sqm}</TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>{formatDateWithTime(row?.in_time)}</TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>{formatDateWithTime(row?.out_time)}</TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>{row?.process_time}</TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>{row?.consumed_item_name}</TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>{row?.liters_of_ammonia_used}</TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>{row?.item_details?.item_grade}</TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>{row?.item_details?.item_pallete_no}</TableCell>
                <TableCell sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}>
                  {row?.item_details?.item_physical_location}
                </TableCell>
                {/* <TableCell sx={{ textAlign: "left", px: 2 }}>
                  <Div
                    onClick={() => {
                      setIssuedSmokingDetails(true);
                      setRowData(row);
                    }}
                  >
                    <InfoIcon sx={{ cursor: "pointer" }} />
                  </Div>
                </TableCell> */}
                <TableCell sx={{ textAlign: "center", px: 2 }}>
                  <Div
                    onClick={() => {
                      setOpen(true);
                      setRowData(row?.item_details);
                    }}
                  >
                    <InfoIcon sx={{ cursor: "pointer" }} />
                  </Div>
                </TableCell>
                <TableCell sx={{ textAlign: "center", px: 2 }}>
                  <Div
                    onClick={() => {
                      setOpenView(true);
                      setRowData(row);
                    }}
                  >
                    <InfoIcon sx={{ cursor: "pointer" }} />
                  </Div>
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>{row?.item_details?.supplier_details?.supplier_name}</TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.individual_dying_remarks ? row?.individual_dying_remarks : "-"}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>{formatDate(row?.date_of_dying)}</TableCell>
                {permissions?.dying_edit == true && (
                  <TableCell
                    sx={{
                      textAlign: "left",
                      px: 1,
                      position: "sticky",
                      right: 0,
                      zIndex: 1,
                      bgcolor: "white",
                      textTransform: "capitalize",
                    }}
                  >
                    {row?.status != "dyed" ? (
                      row.status
                    ) : (
                      <JumboDdMenu
                        icon={<MoreHorizIcon />}
                        menuItems={[
                          {
                            icon: <CheckCircleIcon />,
                            title: "Pass",
                            action: "pass",
                            data: row,
                          },
                          {
                            icon: <CancelIcon />,
                            title: "Reject",
                            action: "reject",
                            data: row,
                          },
                        ]}
                        onClickCallback={handleItemAction}
                      />
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
          {open && <RawInventoryModal open={open} setOpen={setOpen} rowData={rowData} />}
          {issuedSmokingDetails && (
            <IssuedDyingPattasModal open={issuedSmokingDetails} setOpen={setIssuedSmokingDetails} rowData={rowData} />
          )}

          {openView && <ViewImage openView={openView} setOpenView={setOpenView} data={rowData} />}
        </Table>
        <Pagination
          size="medium"
          count={TotalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
            py: 1,
          }}
        />
      </TableContainer>
    </>
  );
}

import {
  Dialog,
  DialogContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

export const CompleteRawItemDetailsModal = ({ open, setOpen, rowData }) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={1000}>
      <DialogContent>
        <Typography variant="h3" color="initial" fontWeight={600}>
          Order Details (Order No : {rowData?.order_no})
        </Typography>
        <TableContainer component={Paper}>
          <Table border={2.5}>
            <TableHead>
              <TableRow
                sx={{
                  bgcolor: "#7352C7",
                  color: "white",
                  "& .MuiTableCell-root": {
                    py: 2,
                  },
                }}
              >
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "80px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Item No
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "350px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Item Name
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "150px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Item Type
                </TableCell>
                {/* <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Required Naturals
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Required Dyeds
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Required Smokes
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "110px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Total
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Dispatched Naturals
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Dispatched Dyeds
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Dispatched Smokes
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "110px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Dispatched Total
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Balance Naturals
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Balance Dyeds
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Balance Smokes
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "110px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Total Balance
                </TableCell> */}
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "110px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Required Quantity
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "110px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Dispatched Quantity
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "110px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Balance Quantity
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Length (cm)
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Width (cm)
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "120px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Sq.m.
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Rate Per Sq.m.
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "120px",
                    verticalAlign: "middle",
                    color: "white",
                    px: 1,
                  }}
                >
                  Total Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData?.raw_order_details?.map((row, i) => (
                <TableRow key={i}>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.item_no}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.order_item_name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.order_item_code}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.required_quantity || 0}
                  </TableCell>{" "}
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.dispatched_quantity || 0}
                  </TableCell>{" "}
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.balance_quantity || 0}
                  </TableCell>
                  {/* <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.required_quantity?.natural || 0}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.required_quantity?.dyed || 0}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.required_quantity?.smoked || 0}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.required_quantity?.total || 0}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.dispatched_quantity?.natural}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.dispatched_quantity?.dyed}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.dispatched_quantity?.smoked}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.dispatched_quantity?.total}
                  </TableCell>

                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.balance_quantity?.natural}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.balance_quantity?.dyed}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.balance_quantity?.smoked}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.balance_quantity?.total}
                  </TableCell> */}

                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.order_length}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.order_width}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.order_sqm}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.order_rate}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.total_order_amount}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ListOptions from "app/components/Dropdown/ListOptions";
import FormTextField1 from "app/components/InputField/FormTextField1";
import { addSupplier } from "app/services/apis/addSupplier";
import { codeList, outerDiv1 } from "app/utils/constants/dropdowns";
import { City, Country, State } from "country-state-city";
import { ErrorMessage, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

export default function AddSupplierDialogue({
  setToggleOpen,
  setHitApi,
  hitApi,
}) {
  const [countryList, setCountryList] = useState(Country.getAllCountries());
  const [StateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { state } = useLocation();
  const [isSubmitting, setSubmitting] = useState(false);
  const supplier = {
    supplier_name: state?.supplier_name ? state?.supplier_name : "",
    email_id: state?.email_id ? state?.email_id : "",
    pan_no: state?.pan_no ? state?.pan_no : "",
    gst_no: state?.gst_no ? state?.gst_no : "",
    contact_Person_name: state?.contact_Person_name
      ? state?.contact_Person_name
      : "",
    country_code: state?.country_code ? state?.country_code : "+91",
    contact_Person_number: state?.contact_Person_number
      ? state?.contact_Person_number
      : "",
    bill_address: state?.bill_address ? state?.bill_address : "",
    delivery_address: state?.delivery_address ? state?.delivery_address : "",
    country: state?.country ? state?.country : "Select",
    state: state?.state ? state?.state : "",
    city: state?.city ? state?.city : "",
    pincode: state?.pincode ? state?.pincode : "",
    supplier_remarks: state?.supplier_remarks ? state?.supplier_remarks : "",
    status: state?.status ? state?.status : "active",

    // supplier_name: state?.supplier_name ? state?.supplier_name : "",
    // supplier_address: state?.supplier_address ? state?.supplier_address : "",
    // supplier_contact_person_email_id: state?.supplier_contact_person_email_id
    //   ? state?.supplier_contact_person_email_id
    //   : "",
  };
  const validationSchema = yup.object({
    supplier_name: yup.string().required("Supplier Name is required"),
    email_id: yup
      .string("Enter  Email ID")
      .email("Enter a valid Email ID")
      .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Enter valid email"),
    // .required(" Email ID is required"),
    // pan_no: yup
    //   .string()
    //   .required("PAN No is required")
    //   .matches(
    //     /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
    //     "Invalid PAN No. Please enter a valid PAN No."
    //   ),
    // gst_no: yup
    //   .string()
    //   .required("GST No is required")
    //   .matches(
    //     /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
    //     "Invalid GST No. Please enter a valid GST No."
    //   ),
    pan_no: yup.string().when("country", {
      is: "India",
      then: yup
        .string()
        // .required("PAN No is required")
        .matches(
          /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
          "Invalid PAN No. Please enter a valid PAN No."
        ),
      otherwise: yup.string(),
    }),

    // gst_no: yup.string().when("country", {
    //   is: "India",
    //   then: yup
    //     .string()
    //     .required("GST No is required")
    //     .matches(
    //       /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
    //       "Invalid GST No. Please enter a valid GST No."
    //     ),
    //   otherwise: yup.string(),
    // }),
    contact_Person_name: yup
      .string("Enter Contact Person Name")
      .required("Contact Person Name is required"),
    contact_Person_number: yup
      .number()
      .typeError("Contact Person Phone must be a number")
      .required("Contact Person Phone is Required"),
    bill_address: yup.string().required("Bill Address is required"),
    delivery_address: yup.string().required("Delivery Address is required"),

    country: yup
      .string("Enter Country")
      .required("Country is required")
      .test(
        "supplier_country-not-select",
        "Please select a valid Country",
        (value) => value !== "Select"
      ),
    state: yup.string("Enter State").required("State is required"),
    city: yup.string("Enter City").required("city is required"),
    pincode: yup
      .string("Enter Pincode")
      // .required("Pincode is required")
      .matches(
        /^\d{6}$/,
        "Invalid PIN code. Please enter a valid 6-digit PIN code."
      ).required("pincode is required"),
  });
  const onUserSave = async (values) => {
    const body = { ...values };
    for (let key in body) {
      if (
        key !== "email_id" &&
        key !== "country" &&
        key !== "state" &&
        key !== "city" &&
        key !== "country_code" &&
        key !== "countryCode" &&
        key != "status" &&
        key != "supplier_remarks"
      ) {
        if (typeof body[key] === "string") {
          body[key] = body[key].toUpperCase();
        }
      }
    }

    setSubmitting(true);
    const data = await addSupplier(body);
    if (data?.data?.status == true) {
      setToggleOpen(false);
      setHitApi(!hitApi);
      Swal.fire({
        icon: "success",
        title: "Supplier Added Successfully",
        text: "",
        timer: 1000,
        showConfirmButton: false,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: data?.data?.message,
        text: "",
      });
    }

    setSubmitting(false);
  };

  const filterStates = (countryIsoCode) => {
    // Use your logic to filter states based on the selected country.
    const filteredStates = State.getAllStates().filter(
      (state) => state.countryCode === countryIsoCode
    );
    setStateList(filteredStates);
  };

  const filterCities = (stateIsoCode, countryCode) => {
    // Use your logic to filter cities based on the selected state.
    const filteredCities = City.getAllCities().filter(
      (city) =>
        city.stateCode === stateIsoCode && city.countryCode == countryCode
    );
    setCityList(filteredCities);
  };

  useEffect(() => {
    if (state) {
      const country = Country.getAllCountries().filter(
        (country) => country.name === state.country
      );
      const stateList = State.getAllStates().filter(
        (country) => country.name === state.state
      );
      filterStates(country[0]?.isoCode);
      filterCities(stateList[0]?.isoCode, country[0]?.isoCode);
    }
  }, []);

  return (
    <Div sx={{ p: 5 }}>
      <Typography variant="h1">Add New Supplier</Typography>
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={supplier}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onUserSave}
          // onSubmit={(values) => console.log(values)}
        >
          {({ values, setFieldValue, errors }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4 }}>
                {/* <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item xs={4}>
                    <FormTextField1 name="supplier_name" label="Company Name" />
                  </Grid>
                </Grid> */}

                {/* <Div
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    columnGap: 4,
                  }}
                > */}
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item xs={3}>
                    <FormTextField1
                      name="supplier_name"
                      label="Supplier Name*"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormTextField1 name="email_id" label="Email Id" />
                  </Grid>
                  <Grid item xs={3}>
                    <FormTextField1
                      name="contact_Person_name"
                      label="Contact Person Name*"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Div sx={outerDiv1}>
                      <Typography variant="h5">
                        Contact Person Phone No*
                      </Typography>
                      <Div sx={{ display: "flex", mt: -1 }}>
                        <ListOptions
                          options={codeList}
                          name={"country_code"}
                          sx={{ minWidth: "80px", maxWidth: "10%" }}
                        />
                        <FormTextField1
                          name="contact_Person_number"
                          sx={{ width: "100%" }}
                        />
                      </Div>
                    </Div>
                  </Grid>
                  <Grid item xs={3}>
                    <Div sx={outerDiv1}>
                      <Typography variant="h5">Country*</Typography>

                      <Select
                        fullWidth
                        value={values?.country}
                        size="small"
                        onChange={(e, key) => {
                          setFieldValue("country", e?.target?.value);
                          setFieldValue("state", "");
                          setFieldValue("city", "");
                          setFieldValue(
                            "countryCode",
                            key.key.replace(/[.$]/g, "")
                          );
                          filterStates(key.key.replace(/[.$]/g, ""));
                        }}
                      >
                        {countryList?.map((country) => {
                          <MenuItem value={"Select"}>Select</MenuItem>;
                          return (
                            <MenuItem
                              value={country?.name}
                              key={country?.isoCode}
                            >
                              {country?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <Div style={{ height: "30px" }}>
                        <ErrorMessage
                          name="country"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </Div>
                  </Grid>
                  <Grid item xs={3}>
                    <Div sx={outerDiv1}>
                      <Typography variant="h5">State*</Typography>
                      <Select
                        fullWidth
                        value={values?.state}
                        size="small"
                        onChange={(e, key) => {
                          setFieldValue("state", e?.target?.value);
                          setFieldValue("city", "");
                          filterCities(
                            key.key.replace(/[.$]/g, ""),
                            values.countryCode
                          );
                        }}
                      >
                        {StateList?.map((state) => {
                          // console.log(state);
                          return (
                            <MenuItem value={state?.name} key={state?.isoCode}>
                              {state?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <ErrorMessage
                        name="state"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Grid>
                  {/* <Grid item xs={3}>
                    <Div sx={outerDiv1}>
                      <Typography variant="h5">City*</Typography>
                      <Select
                        fullWidth
                        value={values?.city}
                        size="small"
                        onChange={(e) => {
                          setFieldValue("city", e.target.value);
                        }}
                      >
                        {cityList?.map((city) => {
                          return (
                            <MenuItem key={city.name} value={city.name}>
                              {city.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <ErrorMessage
                        name="city"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Grid> */}
                  <Grid item xs={3}>
                    <FormTextField1 name="city" label="City" />
                  </Grid>
                  <Grid item xs={3}>
                    <FormTextField1 name="pincode" label="Pincode" />
                  </Grid>

                  <Grid item xs={3}>
                    <FormTextField1 name="bill_address" label="Bill Address*" />
                  </Grid>
                  <Grid item xs={3}>
                    <FormTextField1
                      name="delivery_address"
                      label="Delivery Address*"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormTextField1
                      name="pan_no"
                      label={`PAN Number${errors?.pan_no ? "*" : ""}`}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormTextField1
                      name="gst_no"
                      label={`GST Number${errors?.gst_no ? "*" : ""}`}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h5">Remarks</Typography>
                  <TextField
                    multiline
                    size="small"
                    value={values?.supplier_remarks}
                    sx={{ width: "24%" }}
                    name="supplier_remarks"
                    onChange={(e) =>
                      setFieldValue("supplier_remarks", e.target.value)
                    }
                  />
                </Grid>

                {/* <FormTextField
                  name="supplier_contact_person_email_id"
                  label="Contact Person Email ID"
                /> */}

                {/* </Div> */}

                <Div
                  sx={{
                    width: "93.5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: 3,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setToggleOpen(false);
                    }}
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{ width: "100px" }}
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}

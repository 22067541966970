import Div from "@jumbo/shared/Div";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

export const CuttingDtailsModal = ({ open, setOpen, rowData }) => {
  console.log(rowData)
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={1000}>
      {/* <Div
        sx={{
          display: "flex",
          minWidth: 600,
        }}
      >
        <DialogTitle sx={{ fontWeight: "700" }}>
          <Div>
            <Typography variant="h3" color="initial" fontWeight={600}>
              Cutting Details:-
            </Typography>
          </Div>
        </DialogTitle>
      </Div> */}
      <DialogContent>
        <Typography variant="h3" color="initial" fontWeight={600}>
          Cutting Details
        </Typography>
        <Div>
          <Table size="small">
            <TableHead>
              <TableRow sx={{
              bgcolor: "#7352C7", color: "white", "& .MuiTableCell-root": {
                py: 2,
              },
            }}>
                <TableCell sx={{ color: "white", px: 1 }}> Name</TableCell>
                <TableCell sx={{ color: "white", px: 1 }}> Item Type</TableCell>
                <TableCell sx={{ color: "white", px: 1 }}>Log No.</TableCell>
                <TableCell sx={{ color: "white", px: 1 }}>Bundle No.</TableCell>
                <TableCell sx={{ color: "white", px: 1 }}>
                  Rate Per Sq.m.
                </TableCell>
                <TableCell sx={{ color: "white", px: 1 }}>
                  Original Length (cm)
                </TableCell>
                <TableCell sx={{ color: "white", px: 1 }}>
                  Original Width (cm)
                </TableCell>
                <TableCell sx={{ color: "white", px: 1 }}>
                  Original Patta
                </TableCell>
                <TableCell sx={{ color: "white", px: 1 }}>
                  Original Sq.m.
                </TableCell>
                {/* {rowData?.[0]?.item_details?.final_cutting_quantity?.natural >
                  0 && (
                  <TableCell sx={{ color: "white", px: 1 }}>
                    Natural Pattas
                  </TableCell>
                )} */}
                {/* {rowData?.[0]?.item_details?.final_cutting_quantity?.dyed >
                  0 && (
                  <TableCell sx={{ color: "white", px: 1 }}>
                    Dyed Pattas
                  </TableCell>
                )} */}
                {/* {rowData?.[0]?.item_details?.final_cutting_quantity?.smoked >
                  0 && (
                  <TableCell sx={{ color: "white", px: 1 }}>
                    Smoked Pattas
                  </TableCell>
                )} */}


                <TableCell sx={{ color: "white", px: 1 }}>
                  Cutting Length (cm)
                </TableCell>
                <TableCell sx={{ color: "white", px: 1 }}>
                  Cutting Width (cm)
                </TableCell>
                <TableCell sx={{ color: "white", px: 1 }}>
                  Cutting No Of Patta
                </TableCell>
                <TableCell sx={{ color: "white", px: 1 }}>
                  Cutting Sq.m.
                </TableCell>
                <TableCell sx={{ color: "white", px: 1 }}>Remarks</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData.map((items) =>
                items?.item_details?.item_data?.map((ele) => (
                  <TableRow sx={{ bgcolor: "#EDEBEB" }}>
                    <TableCell sx={{ px: 1 }}>{ele?.item_name}</TableCell>
                    <TableCell sx={{ px: 1 }}>{ele?.item_code}</TableCell>
                    <TableCell sx={{ px: 1 }}>{ele?.item_log_no}</TableCell>
                    <TableCell sx={{ px: 1 }}>{ele?.item_bundle_no}</TableCell>
                    <TableCell sx={{ px: 1 }}>
                      {ele?.item_rate_per_sqm}
                    </TableCell>

  
                    <TableCell sx={{ px: 1 }}>
                      {items?.item_details?.item_data?.[0]?.item_length}
                    </TableCell>
                    <TableCell sx={{ px: 1 }}>
                      {items?.item_details?.item_data?.[0]?.item_width}
                    </TableCell>
                    <TableCell sx={{ px: 1 }}>
                      {
                        items?.item_details?.item_data?.[0]
                          ?.item_received_pattas
                      }
                    </TableCell>
                    <TableCell sx={{ px: 1 }}>
                      {items?.item_details?.item_data?.[0]?.item_received_sqm}
                    </TableCell>
                    {/* {items?.item_details?.final_cutting_quantity?.natural >
                      0 && (
                      <TableCell sx={{ px: 1 }}>
                        {items?.item_details?.final_cutting_quantity?.natural}
                      </TableCell>
                    )} */}
                    {/* {items?.item_details?.final_cutting_quantity?.dyed > 0 && (
                      <TableCell sx={{ px: 1 }}>
                        {items?.item_details?.final_cutting_quantity?.dye d}
                      </TableCell>
                    )}
                    {items?.item_details?.final_cutting_quantity?.smoked >
                      0 && (
                      <TableCell sx={{ px: 1 }}>
                        {items?.item_details?.final_cutting_quantity?.smoked}
                      </TableCell>
                    )} */}
     
                    <TableCell sx={{ px: 1 }}>
                      {items?.item_details?.cutting_length}
                    </TableCell>
                    <TableCell sx={{ px: 1 }}>
                      {items?.item_details?.cutting_width}
                    </TableCell>
                    <TableCell sx={{ px: 1 }}>
                      {items?.item_details?.cutting_no_of_pattas}
                    </TableCell>
                    <TableCell sx={{ px: 1 }}>
                      {items?.item_details?.cutting_sqm}
                    </TableCell>
                    <TableCell sx={{ px: 1 }}>{ele?.item_remark}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </Div>
      </DialogContent>
    </Dialog>
  );
};

import Div from "@jumbo/shared/Div/Div";
import { Autocomplete, Button, MenuItem, Select, TextField, Typography } from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AllApis from "app/Apis";
import ImageUpload from "app/components/UploadImages";
import dayjs from "dayjs";
import { ErrorMessage, Form, Formik } from "formik";
import { Axios } from "index";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { getCurrentDate } from "app/components/Function/getCurrentDate";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { wasteSqmPercentage } from "app/components/Function/wastePercentage";

export default function CreateTapping() {
  const [isSubmitting, setSubmitting] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [gradeList, setGradeList] = useState([]);
  const [palleteList, setPalleteList] = useState([]);
  const [tappingPcs, setTappingPcs] = useState(0);
  const disabledStyling = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#475259",
    },
    "& .MuiInputLabel-root": {
      color: "#475259", // Change label color to blue
    },
  };
  const navigate = useNavigate();
  const { state, pathname } = useLocation();

  // const [other_details, setOtherDetails] = useState(state);
  const createTapping = {
    cutting_id: "",
    tapping_details: [
      {
        tapping_no_of_pcs: "",
        ready_sheet_form_no_of_pcs_available: "",
        tapping_length: "",
        tapping_width: "",
        tapping_sqm: "",
      },
    ],
    tapping_remarks: "",
    ready_sheet_form_pallete_no: "",
    ready_sheet_form_physical_location: "",
    group_no: "",
    issued_for_tapping_id: "",
    issued_for_taping_date: "",
    taping_done_date: getCurrentDate(),
    tapping_images: [],
  };

  const validationSchema = yup.object().shape({
    // tapping_images: yup
    //   .array()
    //   .of(yup.string())
    //   .test(
    //     "at-least-one-item",
    //     "Upload Images",
    //     (value) => value && value.length > 0 && value.length <= 8
    //   ),
    tapping_details: yup.array().of(
      yup.object().shape({
        tapping_no_of_pcs: yup.string().required("Tapping no of Pieces is required"),
        tapping_length: yup.number().typeError("Tapping Length must be a number").required("Length is required"),
        tapping_width: yup.number().typeError("Tapping Width must be a number").required("Width is required"),
        tapping_sqm: yup.number().typeError("Tapping Sq.m. must be a number").required("Tapping Sq.m. is required"),
      })
    ),
    ready_sheet_form_pallete_no: yup.string().required("Ready Sheet Form Pallet No is required"),
    ready_sheet_form_physical_location: yup.string().required("Ready Sheet Form physical location is required"),
    taping_done_date: yup.date().required("Date of tapping is required"),
  });

  const handleSubmit = async (values) => {
    // if (tappingPcs !== state?.group_data?.group_pcs) {
    //   return Swal.fire({
    //     position: "center",
    //     icon: "warning",
    //     text:
    //       tappingPcs < state?.group_data?.group_pcs
    //         ? "Tapping No of Pcs is Less Than Group Pcs"
    //         : "Tapping No of Pcs is Greater Than Group Pcs",
    //     customClass: {
    //       container: "popupImportant",
    //     },
    //     showConfirmButton: false,
    //     timer: 1500,
    //   });
    // }

    //calculate waste sqm
    const totalTappingSqm = values?.tapping_details.reduce((total, item) => {
      return total + item.tapping_sqm;
    }, 0);
    const totalCuttingSqm = state?.cutting_id[0]?.cutting_id.reduce((total, item) => {
      return total + item.item_details.cutting_sqm;
    }, 0);
    if (totalTappingSqm > totalCuttingSqm) {
      Swal.fire({
        title: "Failed",
        text: `Total Tapping Sq.m. (${parseFloat(totalTappingSqm)?.toFixed(2)}) Should Not Greater Than Total Cutting Sq.m. (${parseFloat(
          totalCuttingSqm
        )?.toFixed(2)})`,
        icon: "warning",
        // timer: 1000,
        showConfirmButton: true,
      });
      return;
    }
    let wasteSqm = totalCuttingSqm - totalTappingSqm;
    wasteSqm = parseFloat(wasteSqm.toFixed(2));

    const wastePercentage = wasteSqmPercentage(totalTappingSqm, wasteSqm);

    values.tapping_details.forEach((e, index) => {
      const percentage = parseFloat((e.tapping_sqm / totalTappingSqm) * 100).toFixed(2);
      const wastageValue = parseFloat(wasteSqm * (percentage / 100)).toFixed(2);
      values["tapping_details"][index]["tapping_waste_sqm"] = wastageValue;
      values["tapping_details"][index]["tapping_waste_sqm_percentage"] = percentage;
    });

    const value = {
      ...values,
      cutting_id: state.cutting_id[0].cutting_id[0]._id,
      issued_for_tapping_id: state._id,
      issued_for_taping_date: state.issued_for_taping_date,
      group_no: state.group_data.group_no,
      total_tapping_waste_sqm: wasteSqm,
      total_tapping_waste_sqm_percentage: wastePercentage,
    };
    const form = new FormData();
    // Iterate over the entries of the object and append each key-value pair to the FormData object
    Object.entries(value).forEach((entry) => {
      if (entry[0] == "tapping_images") {
        // form.append(entry[0], JSON.stringify(entry[1]));
        entry[1].forEach((file, index) => {
          form.append(entry[0], file);
        });
      } else if (entry[0] == "tapping_details") {
        form.append(entry[0], JSON.stringify(entry[1]));
      } else {
        form.append(entry[0], entry[1]);
      }
    });

    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    try {
      setSubmitting(true);
      const response = await Axios.post(AllApis.tapping.createTapping, form, config);
      if (response?.data?.status) {
        Swal.fire({
          title: "Tapping Created",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("/dashboard/factory/tapping");
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const calculateSqm = (e, setFieldValue, index, tapping_details) => {
    const { name, value } = e.target;
    const updatedDetails = [...tapping_details]; // Create a shallow copy of the array
    updatedDetails[index] = {
      ...updatedDetails[index],
      [name]: value,
      ...(name === "tapping_no_of_pcs" && {
        ready_sheet_form_no_of_pcs_available: value,
      }),
    };

    // Calculate tapping_sqm based on the updated properties
    const updatedItem = updatedDetails[index];
    const { tapping_length, tapping_width, tapping_no_of_pcs } = updatedItem;
    const sqm = ((tapping_length || 0) * (tapping_width || 0) * (tapping_no_of_pcs || 0)) / 10000;
    updatedItem.tapping_sqm = parseFloat(sqm.toFixed(2));
    //tapping No of pieces
    const total_pieces = updatedDetails.reduce((acc, ele, ind) => {
      return acc + (Number(ele?.tapping_no_of_pcs) ?? 0);
    }, 0);
    setTappingPcs(total_pieces);
    setFieldValue("tapping_details", updatedDetails);
  };
  useEffect(async () => {
    try {
      const gradeList = await Axios.get(`${AllApis.dropdownList.grade}`);
      setGradeList(gradeList?.data?.result);
      const palleteList = await Axios.get(`${AllApis.dropdownList.pallete}`);
      setPalleteList(palleteList?.data?.result);
    } catch (error) {}
  }, []);

  useEffect(async () => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
  }, [refresh]);

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">{pathname == "/dashboard/grouping/edit" ? "Edit Tapping" : "Create Tapping"}</Typography>
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={createTapping}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, errors, initialValues }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ width: "100%" }}>
                <TableContainer component={Paper} sx={{ mb: 3 }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow
                        sx={{
                          bgcolor: "#7352C7",
                          color: "white",
                          "& .MuiTableCell-root": {
                            py: 2,
                          },
                        }}
                      >
                        <TableCell sx={{ color: "white" }}>Group No.</TableCell>
                        <TableCell sx={{ color: "white" }}>Item Name</TableCell>
                        <TableCell sx={{ color: "white" }}>Item Type</TableCell>
                        <TableCell sx={{ color: "white" }}>Total Pattas in Group</TableCell>
                        <TableCell sx={{ color: "white" }}>Grade</TableCell>
                        <TableCell sx={{ color: "white" }}>Pallet No.</TableCell>
                        <TableCell sx={{ color: "white" }}>Physical Location</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{state?.group_data?.group_no}</TableCell>
                        <TableCell>{state?.cutting_id?.[0]?.cutting_id?.[0]?.item_details?.item_data?.[0]?.item_name}</TableCell>
                        <TableCell>{state?.cutting_id?.[0]?.cutting_id?.[0]?.item_details?.item_data?.[0]?.item_code}</TableCell>
                        <TableCell>{state?.group_data?.group_pcs}</TableCell>
                        <TableCell>{state?.group_data?.group_grade}</TableCell>
                        <TableCell>{state?.group_data?.group_pallete_no}</TableCell>
                        <TableCell>{state?.group_data?.group_physical_location}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow
                        sx={{
                          bgcolor: "#7352C7",
                          color: "white",
                          "& .MuiTableCell-root": {
                            py: 2,
                          },
                        }}
                      >
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "100px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Log No
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "100px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Bundle No
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "100px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Length (cm)
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "100px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Width (cm)
                        </TableCell>
                        {state?.cutting_id[0]?.cutting_id?.[0]?.item_details.final_cutting_quantity > 0 && (
                          <TableCell
                            sx={{
                              textAlign: "left",
                              minWidth: "100px",
                              verticalAlign: "middle",
                              px: 1,
                              color: "white",
                            }}
                          >
                            Available Pattas
                          </TableCell>
                        )}

                        {/* {state?.cutting_id[0]?.cutting_id?.[0]?.item_details
                          .final_cutting_quantity.natural > 0 && (
                            <TableCell
                              sx={{
                                textAlign: "left",
                                minWidth: "100px",
                                verticalAlign: "middle",
                                px: 1,
                                color: "white",
                              }}
                            >
                              Issued Pattas
                            </TableCell>
                          )} */}
                        {/* {state?.cutting_id[0]?.cutting_id?.[0]?.item_details
                          .final_cutting_quantity.smoked > 0 && (
                            <TableCell
                              sx={{
                                textAlign: "left",
                                minWidth: "100px",
                                verticalAlign: "middle",
                                px: 1,
                                color: "white",
                              }}
                            >
                              Smoked
                            </TableCell>
                          )} */}
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "95px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Cutting Sq.m.
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "95px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Supplier
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {state?.cutting_id[0]?.cutting_id.map((itemDetails, i) =>
                        itemDetails?.item_details?.item_data?.map((row, i) => (
                          <TableRow key={i}>
                            <TableCell sx={{ textAlign: "left", px: 1 }}>{row?.item_log_no}</TableCell>
                            <TableCell sx={{ textAlign: "left", px: 1 }}>{row?.item_bundle_no}</TableCell>
                            <TableCell sx={{ textAlign: "left", px: 1 }}>{itemDetails?.item_details?.cutting_length}</TableCell>
                            <TableCell sx={{ textAlign: "left", px: 1 }}>{itemDetails?.item_details?.cutting_width}</TableCell>
                            {itemDetails?.item_details.final_cutting_quantity > 0 && (
                              <TableCell sx={{ textAlign: "left", px: 1 }}>{itemDetails?.item_details.final_cutting_quantity}</TableCell>
                            )}

                            {/* {itemDetails?.item_details.final_cutting_quantity
                              .natural > 0 && (
                              <TableCell sx={{ textAlign: "left", px: 1 }}>
                                {
                                  itemDetails?.item_details
                                    .final_cutting_quantity.natural
                                }
                              </TableCell>
                            )}
                            {itemDetails?.item_details.final_cutting_quantity
                              .smoked > 0 && (
                              <TableCell sx={{ textAlign: "left", px: 1 }}>
                                {
                                  itemDetails?.item_details
                                    .final_cutting_quantity.smoked
                                }
                              </TableCell>
                            )} */}
                            <TableCell sx={{ textAlign: "left", px: 1 }}>{itemDetails?.item_details?.cutting_sqm}</TableCell>

                            <TableCell sx={{ textAlign: "left", px: 1 }}>{row?.supplier_details?.supplier_name}</TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Div>
              <Div sx={{ mt: 4, width: "100%" }}>
                <Div
                  sx={{
                    display: "flex",
                    width: "100%",
                    columnGap: 3,
                    flexWrap: "wrap",
                  }}
                >
                  <Div
                    sx={{
                      display: "flex",
                      width: "100%",
                      columnGap: 3,
                      flexWrap: "wrap",
                    }}
                  >
                    <Div sx={{ width: "20%" }}>
                      <Typography variant="h5">Date of Tapping*</Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          sx={{
                            width: "100%",
                            "& .MuiInputBase-input": {
                              padding: 1,
                            },
                          }}
                          format="DD-MM-YYYY"
                          maxDate={dayjs()}
                          defaultValue={values?.taping_done_date !== "" ? dayjs(values?.taping_done_date) : null}
                          onChange={(newValue) => {
                            setFieldValue("taping_done_date", newValue.startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
                          }}
                        />
                        <Div sx={{ height: "30px" }}>
                          <ErrorMessage name="taping_done_date" component="div" style={{ color: "red" }} />
                        </Div>
                      </LocalizationProvider>
                    </Div>
                    {/* <Typography sx={{ width: "20%", mt: 4, fontWeight: 600 }} variant="h5">
                      Total Pcs Selected : {tappingPcs}
                    </Typography> */}
                  </Div>
                  {values?.tapping_details?.map((ele, index) => {
                    return (
                      <>
                        <Div
                          sx={{
                            display: "flex",
                            width: "100%",
                            columnGap: 3,
                            // alignItems: "center",
                          }}
                        >
                          <Div sx={{ width: "20%" }}>
                            {index == 0 && <Typography variant="h5">Tapping Length (cm)*</Typography>}
                            <TextField
                              type="number"
                              // disabled
                              sx={{ width: "100%", ...disabledStyling }}
                              size="small"
                              inputProps={{
                                min: 0,
                              }}
                              onKeyDown={(e) => {
                                const inputValue = parseInt(e.target.value + e.key, 10);

                                if (isNaN(inputValue) || inputValue < 0) {
                                  e.preventDefault();
                                }
                              }}
                              value={ele?.tapping_length}
                              name="tapping_length"
                              onChange={(e) => calculateSqm(e, setFieldValue, index, values?.tapping_details)}
                            />
                            <Div sx={{ height: "30px" }}>
                              <ErrorMessage name={`tapping_details[${index}].tapping_length`} component="div" style={{ color: "red" }} />
                            </Div>
                          </Div>
                          <Div sx={{ width: "20%" }}>
                            {index == 0 && <Typography variant="h5">Tapping Width (cm)*</Typography>}
                            <TextField
                              type="number"
                              // disabled
                              sx={{ width: "100%", ...disabledStyling }}
                              inputProps={{
                                min: 0,
                              }}
                              onKeyDown={(e) => {
                                const inputValue = parseInt(e.target.value + e.key, 10);

                                if (isNaN(inputValue) || inputValue < 0) {
                                  e.preventDefault();
                                }
                              }}
                              size="small"
                              value={ele?.tapping_width}
                              name="tapping_width"
                              onChange={(e) => calculateSqm(e, setFieldValue, index, values?.tapping_details)}
                            />
                            <Div sx={{ height: "30px" }}>
                              <ErrorMessage name={`tapping_details[${index}].tapping_width`} component="div" style={{ color: "red" }} />
                            </Div>
                          </Div>
                          <Div sx={{ width: "20%" }}>
                            {index == 0 && <Typography variant="h5">Tapping No. of Pieces*</Typography>}
                            <TextField
                              type="number"
                              inputProps={{
                                min: 0,
                              }}
                              onKeyDown={(e) => {
                                const inputValue = parseInt(e.target.value + e.key, 10);

                                if (isNaN(inputValue) || inputValue < 0) {
                                  e.preventDefault();
                                }
                              }}
                              sx={{ width: "100%", ...disabledStyling }}
                              size="small"
                              value={ele?.tapping_no_of_pcs}
                              onChange={(e) => calculateSqm(e, setFieldValue, index, values?.tapping_details)}
                              name="tapping_no_of_pcs"
                            />
                            <Div sx={{ height: "30px" }}>
                              <ErrorMessage name={`tapping_details[${index}].tapping_no_of_pcs`} component="div" style={{ color: "red" }} />
                            </Div>
                          </Div>

                          <Div sx={{ width: "20%" }}>
                            {index == 0 && <Typography variant="h5">Tapping Sq.m.*</Typography>}
                            <TextField
                              sx={{ width: "100%", ...disabledStyling }}
                              disabled
                              size="small"
                              value={ele?.tapping_sqm}
                              name="tapping_sqm"
                            />
                            <Div sx={{ height: "30px" }}>
                              <ErrorMessage name="tapping_sqm" component="div" style={{ color: "red" }} />
                            </Div>
                          </Div>
                          <Div sx={{ width: "10%", mt: index == 0 ? 4 : 1 }}>
                            {values?.tapping_details?.length - 1 == index && (
                              <>
                                {index != 0 && (
                                  <RemoveCircleOutlineIcon
                                    sx={{
                                      cursor: "pointer",
                                      mr: 1,
                                      color: "red",
                                    }}
                                    onClick={() => {
                                      const newData = values.tapping_details.filter((_, i) => i !== index);
                                      const total_pieces = newData.reduce((acc, ele, ind) => {
                                        return acc + (Number(ele?.tapping_no_of_pcs) ?? 0);
                                      }, 0);
                                      setTappingPcs(total_pieces);
                                      setFieldValue("tapping_details", newData);
                                    }}
                                  />
                                )}

                                <AddCircleOutlineIcon
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setFieldValue("tapping_details", [...values.tapping_details, initialValues.tapping_details[0]]);
                                  }}
                                />
                              </>
                            )}
                            {values?.tapping_details?.length - 1 !== index && (
                              <RemoveCircleOutlineIcon
                                sx={{ cursor: "pointer", color: "red" }}
                                onClick={() => {
                                  const newData = values.tapping_details.filter((_, i) => i !== index);
                                  const total_pieces = newData.reduce((acc, ele, ind) => {
                                    return acc + (Number(ele?.tapping_no_of_pcs) ?? 0);
                                  }, 0);
                                  setTappingPcs(total_pieces);
                                  setFieldValue("tapping_details", newData);
                                }}
                              />
                            )}
                          </Div>
                        </Div>
                      </>
                    );
                  })}

                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Ready Sheet Form Pallet No.*</Typography>
                    <Autocomplete
                      size="small"
                      id="tags-standard"
                      options={palleteList || []}
                      name="ready_sheet_form_pallete_no"
                      isOptionEqualToValue={(option, value) => option.pallete_no === value}
                      getOptionLabel={(option) => option?.pallete_no || ""}
                      onChange={(e, newValue) => {
                        if (newValue != null) {
                          setFieldValue("ready_sheet_form_pallete_no", newValue?.pallete_no);
                          setFieldValue("ready_sheet_form_physical_location", newValue?.item_physical_location);
                        } else {
                          setFieldValue("ready_sheet_form_pallete_no", "");
                          setFieldValue("ready_sheet_form_physical_location", "");
                        }
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                          {option?.pallete_no}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                    <Div sx={{ height: "30px" }}>
                      <ErrorMessage name="ready_sheet_form_pallete_no" component="div" style={{ color: "red" }} />
                    </Div>
                  </Div>

                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Ready Sheet Form Physical Location*</Typography>
                    <TextField
                      multiline
                      size="small"
                      value={values?.ready_sheet_form_physical_location}
                      sx={{ width: "100%", ...disabledStyling }}
                      name="ready_sheet_form_physical_location"
                      disabled
                    />
                    <ErrorMessage name="ready_sheet_form_physical_location" component="div" style={{ color: "red" }} />
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Tapping Remarks</Typography>
                    <TextField
                      multiline
                      // disabled
                      sx={{ width: "100%" }}
                      size="small"
                      value={values?.tapping_remarks}
                      name="tapping_remarks"
                      onChange={(e) => {
                        setFieldValue("tapping_remarks", e.target.value);
                      }}
                    />
                    <Div sx={{ height: "30px" }}>
                      <ErrorMessage name="tapping_remarks" component="div" style={{ color: "red" }} />
                    </Div>
                  </Div>
                </Div>
              </Div>
              <Div sx={{ width: "50%", mt: 3 }}>
                <Div sx={{ width: "100%" }}>
                  <ImageUpload setFieldValue={setFieldValue} values={values} name={"tapping_images"} />
                  <Div sx={{ height: "30px", mt: -2, textAlign: "center" }}>
                    <ErrorMessage name="tapping_images" component="div" style={{ color: "red" }} />
                  </Div>
                </Div>
              </Div>

              <Div
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 3,
                  mt: 5,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure you want to cancel?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes",
                      cancelButtonText: "No",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigate("/dashboard/factory/tapping");
                      }
                    });
                  }}
                >
                  Cancel
                </Button>

                <LoadingButton loading={isSubmitting} variant="contained" type="submit">
                  Create Tapping
                </LoadingButton>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}

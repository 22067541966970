/* eslint-disable default-case */
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import Div from "@jumbo/shared/Div";
import InfoIcon from "@mui/icons-material/Info";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import AllApis from "app/Apis";
import { formatDate } from "app/components/Function/formatDate";
import FullScreenLoader from "app/components/ListingPageLoader";
import { RawInventoryModal } from "app/components/Modal/rawInventoryModal";
import { getAllIssuedGrouping } from "app/redux/actions/inventoryAction";
import { Axios } from "index";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

export default function IssuedGroupTable({
  page,
  setPage,
  searchTerm,
  filter,
  sort,
  setSort,
  sortBy,
  setSortBy,
}) {
  const dispatch = useDispatch();
  const { issuedGrouping, error, TotalPage, loading } = useSelector(
    (state) => state.inventoryReducer
  );
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowData, setRowData] = useState({});

  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );

  const handleSort = (property) => {
    setSort(sort == "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getAllIssuedGrouping(searchTerm, sort, sortBy, newPage, filter));
  };

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "revert":
        revertGrouping(menuItem.data.item_id?._id);
        break;
    }
  };

  const revertGrouping = async (id) => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Cancel Issued Group",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        try {
          const response = await Axios.post(
            AllApis.grouping.cancelGrouping,
            { id: id },
            config
          );
          if (response) {
            dispatch(getAllIssuedGrouping(searchTerm, sort, sortBy, page, filter));
            Swal.fire({
              title: "Group Cancelled",
              icon: "success",
              timer: 1000,
              showConfirmButton: false,
            });
          }
        } catch (error) {
          Swal.fire({
            title: error.message,
            icon: "error",
            timer: 1000,
            showConfirmButton: false,
          });
        } finally {
          setIsLoading(false);
        }
      }
    });
  };
  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <>
      {isLoading && <FullScreenLoader />}

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow sx={{
              bgcolor: "#7352C7", color: "white", "& .MuiTableCell-root": {
                py: 2,
              },
            }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "300px",
                  verticalAlign: "middle",
                  // px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_id.item_name"}
                  direction={sort}
                  onClick={() => handleSort("item_id.item_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_id.item_code"}
                  direction={sort}
                  onClick={() => handleSort("item_id.item_code")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Type
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "90px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_id.item_log_no"}
                  direction={sort}
                  onClick={() => handleSort("item_id.item_log_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Log No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "110px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_id.item_bundle_no"}
                  direction={sort}
                  onClick={() => handleSort("item_id.item_bundle_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Bundle No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "115px",
                  verticalAlign: "middle",
                  p: 0,
                  pl:1
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_id.item_length"}
                  direction={sort}
                  onClick={() => handleSort("item_id.item_length")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Length (cm)
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "110px",
                  verticalAlign: "middle",
                  p: 0,
                  pl:1
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_id.item_width"}
                  direction={sort}
                  onClick={() => handleSort("item_id.item_width")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Width (cm)
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "110px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_id.item_available_pattas"}
                  direction={sort}
                  onClick={() => handleSort("item_id.item_available_pattas")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Avl Pattas
                </TableSortLabel>
              </TableCell>
              {/* <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "108px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={
                    sortBy === "item_id.item_available_quantities.natural"
                  }
                  direction={sort}
                  onClick={() =>
                    handleSort("item_id.item_available_quantities.natural")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Available Naturals
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "108px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_id.item_available_quantities.smoked"}
                  direction={sort}
                  onClick={() =>
                    handleSort("item_id.item_available_quantities.smoked")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Available Smoked
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "108px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_id.item_available_quantities.dyed"}
                  direction={sort}
                  onClick={() =>
                    handleSort("item_id.item_available_quantities.dyed")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Available Dyed
                </TableSortLabel>
              </TableCell> */}

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "135px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_id.item_available_sqm"}
                  direction={sort}
                  onClick={() => handleSort("item_id.item_available_sqm")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Avl Total Sq.m.
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "75px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_id.item_grade"}
                  direction={sort}
                  onClick={() => handleSort("item_id.item_grade")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Grade
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "110px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_id.item_pallete_no"}
                  direction={sort}
                  onClick={() => handleSort("item_id.item_pallete_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Pallet No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "155px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_id.item_physical_location"}
                  direction={sort}
                  onClick={() => handleSort("item_id.item_physical_location")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Physical Location
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "85px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Details
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "155px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_id.supplier_details.supplier_name"}
                  direction={sort}
                  onClick={() =>
                    handleSort("item_id.supplier_details.supplier_name")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Supplier
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Issued Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "created_employee_id.first_name"}
                  direction={sort}
                  onClick={() => handleSort("created_employee_id.first_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Created By
                </TableSortLabel>
              </TableCell>
              {permissions?.inventory_edit == true && (
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "95px",
                    verticalAlign: "middle",
                    color: "white",
                    px: 1,
                    position: "sticky",
                    right: 0,
                    height: "58px",
                    zIndex: 1,
                    bgcolor: "#7352C7",
                  }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {issuedGrouping?.map((row, i) => (
              <TableRow key={i}>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.item_id?.item_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.item_id?.item_code}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.item_id?.item_log_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.item_id?.item_bundle_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.item_id?.item_length}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.item_id?.item_width}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.item_id?.item_available_pattas}
                </TableCell>
                {/* <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row.item_id?.item_available_quantities?.natural || 0}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row.item_id?.item_available_quantities?.smoked || 0}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row.item_id?.item_available_quantities?.dyed || 0}
                </TableCell> */}

                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.item_id?.item_available_sqm}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.item_id?.item_grade}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.item_id?.item_pallete_no}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {row?.item_id?.item_physical_location}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 2 }}>
                  <Div
                    onClick={() => {
                      setOpen(true);
                      setRowData(row?.item_id);
                    }}
                  >
                    <InfoIcon sx={{ cursor: "pointer" }} />
                  </Div>
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.item_id?.supplier_details?.supplier_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {formatDate(row?.created_at)}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {row?.created_employee_id?.first_name}{" "}
                  {row?.created_employee_id?.last_name}
                </TableCell>

                {permissions?.inventory_edit == true && (
                  <TableCell
                    sx={{
                      textAlign: "left",
                      px: 1,
                      position: "sticky",
                      right: 0,
                      zIndex: 1,
                      bgcolor: "white",
                    }}
                  >
                    <JumboDdMenu
                      icon={<MoreHorizIcon />}
                      menuItems={[
                        {
                          icon: <SettingsBackupRestoreIcon />,
                          title: "Revert Grouping",
                          action: "revert",
                          data: row,
                        },
                      ]}
                      onClickCallback={handleItemAction}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
          {open && (
            <RawInventoryModal
              open={open}
              setOpen={setOpen}
              rowData={rowData}
            />
          )}
        </Table>
        <Pagination size="medium"
          count={TotalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
            py:1
          }}
        />
      </TableContainer>
    </>
  );
}

import Div from "@jumbo/shared/Div";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

export const RawMaterialDetailModal = ({ open, setOpen, rowData, tab }) => {
  console.log(rowData)
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth>
      <DialogContent>
        <Typography variant="h3" color="initial" fontWeight={600}>
          Invoice Details
        </Typography>
        {/* <Div sx={{ mb: 2 }}>
          <Div sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
          </Div>
          <Typography>
            <strong>Invoice No : </strong> 123456
          </Typography>
          <Typography>
            <strong>Supplier Name : </strong> 123456
          </Typography>
          <Typography>
            <strong>Gst : </strong> 123456
          </Typography>
          {rowData?.supplier_details?.state && rowData?.supplier_details?.state?.trim()?.toLowerCase() !== "maharashtra" ? <Typography>
            <strong>IGST : </strong> 123456
          </Typography> : <>
            <Typography>
              <strong>CGST : </strong> 123456
            </Typography>
            <Typography>
              <strong>SGST : </strong> 123456
            </Typography>
          </>}
          <Typography>
            <strong>Currency : </strong>{rowData?.currency}
          </Typography>
          {rowData?.currency && rowData?.currency?.trim()?.toLowerCase() !== "inr" && <Typography>
            <strong>Conversion Rate : </strong>{rowData?.conversion_rate}
          </Typography>}
          <Typography>
            <strong>Item Rate Per Sq.m. : </strong>{rowData?.currency && rowData?.currency?.trim()?.toLowerCase() !== "inr" ? rowData?.item_rate_per_sqm_for_currency : rowData?.item_rate_per_sqm}
          </Typography>
          
        </Div> */}

        <Divider />
        <Div sx={{ width: "100%", overflowX: "scroll", mb: 2 }}>
          <Table size="medium">
            <TableHead>
              <TableRow
                sx={{
                  bgcolor: "#7352C7",
                  color: "white",
                  "& .MuiTableCell-root": {
                    // py: 2,
                  },
                }}
              >
                <TableCell sx={{ color: "white", minWidth: "100px" }}>Invoice No</TableCell>
                {/* <TableCell sx={{ color: "white", minWidth: "130px" }}>Suppier Name</TableCell> */}
                <TableCell sx={{ color: "white", minWidth: "100px" }}>Currency</TableCell>
                {rowData?.currency && rowData?.currency?.trim()?.toLowerCase() !== "inr" && <TableCell sx={{ color: "white", minWidth: "150px" }}>Conversion Rate</TableCell>}
                {rowData?.currency && rowData?.currency?.trim()?.toLowerCase() !== "inr" && <TableCell sx={{ color: "white", minWidth: "150px" }}>Rate Per Sq.m. For Currency</TableCell>}
                <TableCell sx={{ color: "white", minWidth: "150px" }}>Rate Per Sq.m</TableCell>
                <TableCell sx={{ color: "white", minWidth: "90px" }}>Gst</TableCell>
                {rowData?.supplier_details?.state && rowData?.supplier_details?.state?.trim()?.toLowerCase() !== "maharashtra" ? <TableCell sx={{ color: "white", minWidth: "90px" }}>IGST</TableCell> : <>
                  <TableCell sx={{ color: "white", minWidth: "90px" }}>CGST</TableCell>
                  <TableCell sx={{ color: "white", minWidth: "90px" }}>SGST</TableCell> </>}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ bgcolor: "#EDEBEB" }}>
                <TableCell sx={{ textAlign: "left" }}>{rowData?.invoice_no || "N/A"}</TableCell>
                {/* <TableCell sx={{ textAlign: "left" }}>{rowData?.supplier_details?.supplier_name}</TableCell> */}
                <TableCell sx={{ textAlign: "left" }}>{rowData?.currency || "N/A"}</TableCell>
                {rowData?.currency && rowData?.currency?.trim()?.toLowerCase() !== "inr" && <TableCell sx={{ textAlign: "left" }}>{rowData?.conversion_rate || "N/A"}</TableCell>}
                {rowData?.currency && rowData?.currency?.trim()?.toLowerCase() !== "inr" && <TableCell sx={{ textAlign: "left" }}>{rowData?.item_rate_per_sqm_for_currency || "N/A"}</TableCell>}
                <TableCell sx={{ textAlign: "left" }}>{rowData?.item_rate_per_sqm || "N/A"}</TableCell>
                <TableCell sx={{ textAlign: "left" }}>{rowData?.gst || "N/A"}</TableCell>
                {rowData?.supplier_details?.state && rowData?.supplier_details?.state?.trim()?.toLowerCase() !== "maharashtra" ? <TableCell sx={{ textAlign: "left" }}>{rowData?.igst || "N/A"}</TableCell> :
                  <>
                    <TableCell sx={{ textAlign: "left" }}>{rowData?.cgst || "N/A"}</TableCell>
                    <TableCell sx={{ textAlign: "left" }}>{rowData?.sgst || "N/A"}</TableCell>
                  </>}
                {/* <TableCell>{total}</TableCell> */}
              </TableRow>
            </TableBody>
          </Table>
        </Div>
        <Div sx={{ mb: 1 }}>
          <Typography variant="h3" color="initial" fontWeight={600}>
            Item Details
          </Typography>
        </Div>
        <Div
          sx={{
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            // flexDirection: "column",
            // gap: 3,
          }}
        >
          <Div>
            <Table size="small">
              <TableHead>
                <TableRow
                  sx={{
                    bgcolor: "#7352C7",
                    color: "white",
                    "& .MuiTableCell-root": {
                      // py: 2,
                    },
                  }}
                >
                  <TableCell sx={{ color: "white" }}>Received Pattas</TableCell>
                  <TableCell sx={{ color: "white" }}>Available Pattas</TableCell>
                  <TableCell sx={{ color: "white" }}>Rejected Pattas</TableCell>
                  {/* <TableCell sx={{ color: "white" }}>Total</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ bgcolor: "#EDEBEB" }}>
                  <TableCell sx={{ textAlign: "left" }}>{rowData.item_received_pattas}</TableCell>
                  <TableCell sx={{ textAlign: "left" }}>{rowData.item_available_pattas}</TableCell>
                  <TableCell sx={{ textAlign: "left" }}>{rowData.item_rejected_pattas}</TableCell>
                  {/* <TableCell>{total}</TableCell> */}
                </TableRow>
              </TableBody>
            </Table>
          </Div>
        </Div>
      </DialogContent>
    </Dialog>
  );
};

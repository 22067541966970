/* eslint-disable default-case */
import {
  Checkbox,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Div from "@jumbo/shared/Div";
import InfoIcon from "@mui/icons-material/Info";
import { LoadingButton } from "@mui/lab";
import { GroupBundlesModal } from "app/pages/Grouping/Modal/groupBundlesDetails";
import { getIssuedForDyingGroupList } from "app/redux/actions/factoryAction";
import { displayDateFun } from "app/utils/constants/functions";
import { useNavigate } from "react-router-dom";
import FullScreenLoader from "app/components/ListingPageLoader";

export default function IssuedForDyingGroups({
  page,
  setPage,
  searchTerm,
  filter,
  sort,
  setSort,
  sortBy,
  setSortBy,
  addGroup,
  setAddGroup,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { issuedForDyingGroups, error, TotalPage, loading } = useSelector(
    (state) => state.factoryReducer
  );
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState({});

  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );

  const handleSort = (property) => {
    setSort(sort == "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(
      getIssuedForDyingGroupList(searchTerm, sort, sortBy, newPage, filter)
    );
  };

  const handleCheckbox = (e, row) => {
    if (e.target.checked == true) {
      const selectedArr = [...addGroup];
      selectedArr.push(row);
      setAddGroup(selectedArr);
    } else {
      const selectedArr = [...addGroup];
      const deletedIds = selectedArr.filter((ele) => ele != row);
      setAddGroup(deletedIds);
    }
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      {addGroup?.length > 0 && (
        <Div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
            bgcolor: "#dbdbdb",
            p: 2,
            borderRadius: "5px",
          }}
        >
          <Typography variant="h5" sx={{ color: "black", mt: 1 }}>
            {addGroup?.length} Groups Selected
          </Typography>
          <Div sx={{ display: "flex", columnGap: 3 }}>
            <LoadingButton
              variant="contained"
              onClick={() =>
                navigate("/dashboard/factory/dying/create-dying-group", {
                  state: addGroup,
                })
              }
            >
              Create Dying Group
            </LoadingButton>

            <LoadingButton
              variant="contained"
              color="error"
              onClick={() => setAddGroup([])}
            >
              Cancel
            </LoadingButton>
          </Div>
        </Div>
      )}

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow sx={{
              bgcolor: "#7352C7", color: "white", "& .MuiTableCell-root": {
                py: 2,
              },
            }}>
              {permissions?.dying_create && (
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "95px",
                    color: "white",
                    // px: 1,
                  }}
                >
                  Select
                </TableCell>
              )}
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "110px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_id.group_no"}
                  direction={sort}
                  onClick={() => handleSort("group_id.group_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Group No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "300px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_id.item_details.item_name"}
                  direction={sort}
                  onClick={() => handleSort("group_id.item_details.item_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "110px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_id.item_details.item_code"}
                  direction={sort}
                  onClick={() => handleSort("group_id.item_details.item_code")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Type
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "165px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_id.group_length"}
                  direction={sort}
                  onClick={() => handleSort("group_id.group_length")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Group Length (cm)
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "165px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_id.group_width"}
                  direction={sort}
                  onClick={() => handleSort("group_id.group_width")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Group Width (cm)
                </TableSortLabel>
              </TableCell>
         
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "108px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_id.group_pcs"}
                  direction={sort}
                  onClick={() => handleSort("group_id.group_pcs")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  No of Pcs
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "128px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_id.group_no_of_pattas_available"}
                  direction={sort}
                  onClick={() =>
                    handleSort("group_id.group_no_of_pattas_available")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  No of Pattas
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_id.group_sqm_available"}
                  direction={sort}
                  onClick={() => handleSort("group_id.group_sqm_available")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Group Sq.m.
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "175px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_id.total_item_sqm_available"}
                  direction={sort}
                  onClick={() =>
                    handleSort("group_id.total_item_sqm_available")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Total Item Sq.m. Avl
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "70px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_id.group_grade"}
                  direction={sort}
                  onClick={() => handleSort("group_id.group_grade")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Grade
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "105px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_id.orientation"}
                  direction={sort}
                  onClick={() => handleSort("group_id.orientation")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Orientation
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_id.book_type"}
                  direction={sort}
                  onClick={() => handleSort("group_id.book_type")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Formation Type
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_id.group_pallete_no"}
                  direction={sort}
                  onClick={() => handleSort("group_id.group_pallete_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Group Pallet No
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "160px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_id.group_physical_location"}
                  direction={sort}
                  onClick={() => handleSort("group_id.group_physical_location")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Physical Location
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "155px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Issued Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "115px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Bundle Details
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {issuedForDyingGroups?.map((row, i) => (
              <TableRow key={i}>
                {permissions?.dying_create && (
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    <Checkbox
                      onClick={(event) => handleCheckbox(event, row)}
                      color="primary"
                      checked={addGroup?.some((ele) => ele == row)}
                    />
                  </TableCell>
                )}
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_id?.group_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_id?.item_details?.[0]?.item_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_id?.item_details?.[0]?.item_code}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_id?.group_length}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_id?.group_width}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_id?.group_pcs}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_id?.group_no_of_pattas_available}
                </TableCell>
     
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_id?.group_sqm_available?.toFixed(2)}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_id?.total_item_sqm_available?.toFixed(2)}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_id?.group_grade}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {row?.group_id?.orientation}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {row?.group_id?.book_type}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {row?.group_id?.group_pallete_no}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {row?.group_id?.group_physical_location}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {displayDateFun(row?.created_at)}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 2 }}>
                  <Div
                    onClick={() => {
                      setOpen(true);
                      setRowData(row?.group_id);
                    }}
                  >
                    <InfoIcon sx={{ cursor: "pointer" }} />
                  </Div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {open && (
            <GroupBundlesModal
              open={open}
              setOpen={setOpen}
              rowData={rowData}
            />
          )}
        </Table>
        <Pagination size="medium"
          count={TotalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
            py:1
          }}
        />
      </TableContainer>
    </>
  );
}

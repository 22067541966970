import Div from "@jumbo/shared/Div";
import {
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

export const CuttingBundleModal = ({ open, setOpen, rowData }) => {
  const handleClose = () => {
    setOpen(false);
  };
  console.log(rowData, "rowData");
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={1000}>
      <DialogContent>
        <Typography variant="h3" color="initial" fontWeight={600}>
          Cutting Bundles Details:-
        </Typography>
        <Div>
          <Table size="small">
            <TableHead>
              <TableRow sx={{
              bgcolor: "#7352C7", color: "white", "& .MuiTableCell-root": {
                py: 2,
              },
            }}>
                <TableCell sx={{ color: "white" }}>Item Name</TableCell>
                <TableCell sx={{ color: "white" }}>Item Type</TableCell>
                <TableCell sx={{ color: "white" }}>Rate Per Sq.m.</TableCell>
                <TableCell sx={{ color: "white" }}>Log No.</TableCell>
                <TableCell sx={{ color: "white" }}>Bundle No.</TableCell>
                {rowData[0]?.final_cutting_quantity && (
                  <>
                    <TableCell sx={{ color: "white" }}>
                      Cutting Length (cm)
                    </TableCell>
                    <TableCell sx={{ color: "white" }}>Cutting Width (cm)</TableCell>
                    <TableCell sx={{ color: "white" }}>No. Of Pattas</TableCell>
                    <TableCell sx={{ color: "white" }}>Sq.m.</TableCell>
                  </>
                )}
                {/* {rowData.some(
                  (ele) => ele?.waste_cutting_quantity?.waste_pattas > 0
                ) && ( */}
                  <>
                    {/* <TableCell sx={{ color: "white" }}>
                      Waste No. Of Pattas
                    </TableCell> */}
                    <TableCell sx={{ color: "white" }}> Waste Sq.m.</TableCell>
                    <TableCell sx={{ color: "white" }}>
                      {" "}
                      Waste Percentage
                    </TableCell>
                  </>
                {/* )} */}
                {/* <TableCell sx={{ color: "white" }}>Natural</TableCell>
                <TableCell sx={{ color: "white" }}>Dyed</TableCell>
                <TableCell sx={{ color: "white" }}>Smoked</TableCell> */}
                <TableCell sx={{ color: "white" }}>Total Available</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData.map((ele) => {
                return (
                  <TableRow sx={{ bgcolor: "#EDEBEB" }}>
                    <TableCell>{ele?.item_name}</TableCell>
                    <TableCell>{ele?.item_code}</TableCell>
                    <TableCell>{ele?.item_rate_per_sqm}</TableCell>
                    <TableCell>{ele?.item_log_no}</TableCell>
                    <TableCell>{ele?.item_bundle_no}</TableCell>
                    {ele?.final_cutting_quantity && (
                      <>
                        <TableCell>{ele?.cutting_length}</TableCell>
                        <TableCell>{ele?.cutting_width}</TableCell>
                        <TableCell>{ele?.cutting_no_of_pattas}</TableCell>
                        <TableCell>{ele?.cutting_sqm}</TableCell>
                      </>
                    )}
                    {/* {rowData.some(
                      (ele) => ele?.waste_cutting_quantity?.waste_pattas > 0
                    ) && ( */}
                      <>
                        {/* <TableCell>
                          {ele?.waste_cutting_quantity?.waste_pattas}
                        </TableCell> */}
                        <TableCell>
                          {ele?.waste_cutting_quantity?.waste_sqm}
                        </TableCell>
                        <TableCell>
                          {ele?.waste_cutting_quantity?.waste_sqm_percentage} %
                        </TableCell>
                      </>
                    {/* )} */}
                    {/* <TableCell>{ele?.cutting_quantity?.natural}</TableCell>
                    <TableCell>{ele?.cutting_quantity?.dyed}</TableCell>
                    <TableCell>{ele?.cutting_quantity?.smoked}</TableCell> */}
                    <TableCell>{ele?.cutting_quantity}</TableCell>
                    {/* <TableCell>{ele?.cutting_quantity?.total}</TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Div>
      </DialogContent>
    </Dialog>
  );
};

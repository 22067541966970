// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popupImportant {
  z-index: 300000;
}

/* Styling for table rows */
.MuiTableRow-root {
  &:nth-of-type(even) {
    background-color: #f5f5f5;
  }
}

/* Styling for active sorting label */
.MuiTableSortLabel-root.MuiTableSortLabel-active {
  color: #f5f5f5;
}

/* Styling for ascending sorting label */
.MuiTableSortLabel-iconDirectionAsc {
  color: #f5f5f5;
}

/* Styling for descending sorting label */
.MuiTableSortLabel-iconDirectionDesc {
  color: #f5f5f5;
}
`, "",{"version":3,"sources":["webpack://./src/app/App.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA,2BAA2B;AAC3B;EACE;IACE,yBAAyB;EAC3B;AACF;;AAEA,qCAAqC;AACrC;EACE,cAAc;AAChB;;AAEA,wCAAwC;AACxC;EACE,cAAc;AAChB;;AAEA,yCAAyC;AACzC;EACE,cAAc;AAChB","sourcesContent":[".popupImportant {\n  z-index: 300000;\n}\n\n/* Styling for table rows */\n.MuiTableRow-root {\n  &:nth-of-type(even) {\n    background-color: #f5f5f5;\n  }\n}\n\n/* Styling for active sorting label */\n.MuiTableSortLabel-root.MuiTableSortLabel-active {\n  color: #f5f5f5;\n}\n\n/* Styling for ascending sorting label */\n.MuiTableSortLabel-iconDirectionAsc {\n  color: #f5f5f5;\n}\n\n/* Styling for descending sorting label */\n.MuiTableSortLabel-iconDirectionDesc {\n  color: #f5f5f5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

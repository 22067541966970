import Div from "@jumbo/shared/Div";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { displayDateFun } from "app/utils/constants/functions";

export const TappingDtailsModal = ({ open, setOpen, rowData }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={1000}>
      <Div
        sx={{
          display: "flex",
          minWidth: 600,
        }}
      >
        <DialogTitle sx={{ fontWeight: "700" }}>
          <Div>
            <Typography variant="h3" color="initial" fontWeight={600}>
              Tapping Details:-
            </Typography>
          </Div>
        </DialogTitle>
      </Div>
      <DialogContent>
        <Div>
          <Table size="small">
            <TableHead>
              <TableRow sx={{
              bgcolor: "#7352C7", color: "white", "& .MuiTableCell-root": {
                py: 2,
              },
            }}>
                <TableCell sx={{ color: "white", px: 1 }}>
                  Tapping Length (cm)
                </TableCell>
                <TableCell sx={{ color: "white", px: 1 }}>
                  {" "}
                  Tapping Width (cm)
                </TableCell>
                <TableCell sx={{ color: "white", px: 1 }}>
                  Tapping No Of Pieces
                </TableCell>
                <TableCell sx={{ color: "white", px: 1 }}>
                  Tapping Sq.m.
                </TableCell>
                <TableCell sx={{ color: "white", px: 1 }}>
                  Tapping Date
                </TableCell>
                <TableCell sx={{ color: "white", px: 1 }}>
                  Tapping Remarks
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ bgcolor: "#EDEBEB" }}>
                <TableCell sx={{ px: 1 }}>{rowData?.tapping_length}</TableCell>
                <TableCell sx={{ px: 1 }}>{rowData?.tapping_width}</TableCell>
                <TableCell sx={{ px: 1 }}>
                  {rowData?.tapping_no_of_pcs}
                </TableCell>
                <TableCell sx={{ px: 1 }}>{rowData?.tapping_sqm}</TableCell>
                <TableCell sx={{ px: 1 }}>
                  {" "}
                  {displayDateFun(rowData?.taping_done_date)}
                </TableCell>
                <TableCell sx={{ px: 1 }}>{rowData?.tapping_remarks}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Div>
      </DialogContent>
    </Dialog>
  );
};

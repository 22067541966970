export const branches = [
    {
        title: "Head Office",
        value: "head-office",
        address: "488, blingum road, JP Street, NJ, California",
        phone: "+01-992856-8535",
        email: "bob.bulider@jobportal.com"
    },
    {
        title: "Branch Office",
        value: "branch-office",
        address: "Rani bajar, Jaipur road, Bikaner, Rajasthan",
        phone: "+01-336558-8535",
        email: "john-smith@jobportal.com"
    }
];
